@charset "UTF-8";
/* ------------------------------------------------------------------------------
 *
 *  # Main project sass file
 *
 *  Common sass file with imports
 *
 *  Version: 1.0
 *  Latest update: Dec 20, 2017
 *
 * ---------------------------------------------------------------------------- */
/* Core
-------------------------------------------------- */
/* custom variables*/
/**
 *
 * Custom variables
 *
 */
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block, .btn .icon-circle {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1,
.file-path,
.var-type,
.var-value {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1,
.file-path,
.var-type,
.var-value {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3, h4,
.h4,
h5,
.h5,
h6,
.h6, ol li,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4, h3,
.h3,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5, h2, .has-separator,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0,
pre > code[class*='language'] {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0,
pre > code[class*='language'] {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1,
code[class*='language'] {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1,
code[class*='language'] {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary, h1 {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted, .file-path.var-type,
.var-type,
.var-value.var-type {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* Color variants - https://codepen.io/migli/pen/QaGNVp */
/*  Add color variants to $colors*/
/* remap grays */
/* remap colors */
/* from Bootstrap 4 utilities - replaced $theme-colors with $colors */
/* text colors*/
.text-blue {
  color: #0e73cc !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #094b84 !important; }

.text-red {
  color: #fc4848 !important; }

a.text-red:hover, a.text-red:focus {
  color: #f40404 !important; }

.text-pink {
  color: #e91e63 !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #aa1145 !important; }

.text-yellow {
  color: #ffc107 !important; }

a.text-yellow:hover, a.text-yellow:focus {
  color: #ba8b00 !important; }

.text-gray, .text-light {
  color: #8c8476 !important; }

a.text-gray:hover, a.text-light:hover, a.text-gray:focus, a.text-light:focus {
  color: #635d53 !important; }

.text-gray-dark {
  color: #38352f !important; }

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #0e0e0c !important; }

.text-primary, h1 {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-blue-100 {
  color: #e7f1fa !important; }

a.text-blue-100:hover, a.text-blue-100:focus {
  color: #a8cced !important; }

.text-blue-200 {
  color: #c3dcf2 !important; }

a.text-blue-200:hover, a.text-blue-200:focus {
  color: #84b7e4 !important; }

.text-blue-300 {
  color: #87b9e6 !important; }

a.text-blue-300:hover, a.text-blue-300:focus {
  color: #4894d9 !important; }

.text-blue-400 {
  color: #4a96d9 !important; }

a.text-blue-400:hover, a.text-blue-400:focus {
  color: #2570b1 !important; }

.text-blue-500 {
  color: #0e73cc !important; }

a.text-blue-500:hover, a.text-blue-500:focus {
  color: #094b84 !important; }

.text-blue-600 {
  color: #0b5699 !important; }

a.text-blue-600:hover, a.text-blue-600:focus {
  color: #062e52 !important; }

.text-blue-700 {
  color: #073a66 !important; }

a.text-blue-700:hover, a.text-blue-700:focus {
  color: #02111e !important; }

.text-blue-800 {
  color: #041d33 !important; }

a.text-blue-800:hover, a.text-blue-800:focus {
  color: black !important; }

.text-blue-900 {
  color: #02111f !important; }

a.text-blue-900:hover, a.text-blue-900:focus {
  color: black !important; }

.text-red-100 {
  color: #ffeded !important; }

a.text-red-100:hover, a.text-red-100:focus {
  color: #ffa1a1 !important; }

.text-red-200 {
  color: #fed1d1 !important; }

a.text-red-200:hover, a.text-red-200:focus {
  color: #fc8686 !important; }

.text-red-300 {
  color: #fea4a4 !important; }

a.text-red-300:hover, a.text-red-300:focus {
  color: #fd5858 !important; }

.text-red-400 {
  color: #fd7676 !important; }

a.text-red-400:hover, a.text-red-400:focus {
  color: #fc2b2b !important; }

.text-red-500 {
  color: #fc4848 !important; }

a.text-red-500:hover, a.text-red-500:focus {
  color: #f40404 !important; }

.text-red-600 {
  color: #bd3636 !important; }

a.text-red-600:hover, a.text-red-600:focus {
  color: #822525 !important; }

.text-red-700 {
  color: #7e2424 !important; }

a.text-red-700:hover, a.text-red-700:focus {
  color: #431313 !important; }

.text-red-800 {
  color: #3f1212 !important; }

a.text-red-800:hover, a.text-red-800:focus {
  color: #040101 !important; }

.text-red-900 {
  color: #260b0b !important; }

a.text-red-900:hover, a.text-red-900:focus {
  color: black !important; }

.text-pink-100 {
  color: #fde9ef !important; }

a.text-pink-100:hover, a.text-pink-100:focus {
  color: #f7a3bc !important; }

.text-pink-200 {
  color: #fac7d8 !important; }

a.text-pink-200:hover, a.text-pink-200:focus {
  color: #f481a7 !important; }

.text-pink-300 {
  color: #f48fb1 !important; }

a.text-pink-300:hover, a.text-pink-300:focus {
  color: #ed4980 !important; }

.text-pink-400 {
  color: #ef568a !important; }

a.text-pink-400:hover, a.text-pink-400:focus {
  color: #e3155b !important; }

.text-pink-500 {
  color: #e91e63 !important; }

a.text-pink-500:hover, a.text-pink-500:focus {
  color: #aa1145 !important; }

.text-pink-600 {
  color: #af174a !important; }

a.text-pink-600:hover, a.text-pink-600:focus {
  color: #6b0e2d !important; }

.text-pink-700 {
  color: #750f32 !important; }

a.text-pink-700:hover, a.text-pink-700:focus {
  color: #310615 !important; }

.text-pink-800 {
  color: #3a0819 !important; }

a.text-pink-800:hover, a.text-pink-800:focus {
  color: black !important; }

.text-pink-900 {
  color: #23050f !important; }

a.text-pink-900:hover, a.text-pink-900:focus {
  color: black !important; }

.text-yellow-100 {
  color: #fff9e6 !important; }

a.text-yellow-100:hover, a.text-yellow-100:focus {
  color: #ffe79a !important; }

.text-yellow-200 {
  color: #fff0c1 !important; }

a.text-yellow-200:hover, a.text-yellow-200:focus {
  color: #ffdd75 !important; }

.text-yellow-300 {
  color: #ffe083 !important; }

a.text-yellow-300:hover, a.text-yellow-300:focus {
  color: #ffcd37 !important; }

.text-yellow-400 {
  color: #ffd145 !important; }

a.text-yellow-400:hover, a.text-yellow-400:focus {
  color: #f8ba00 !important; }

.text-yellow-500 {
  color: #ffc107 !important; }

a.text-yellow-500:hover, a.text-yellow-500:focus {
  color: #ba8b00 !important; }

.text-yellow-600 {
  color: #bf9105 !important; }

a.text-yellow-600:hover, a.text-yellow-600:focus {
  color: #745803 !important; }

.text-yellow-700 {
  color: #806104 !important; }

a.text-yellow-700:hover, a.text-yellow-700:focus {
  color: #362902 !important; }

.text-yellow-800 {
  color: #403002 !important; }

a.text-yellow-800:hover, a.text-yellow-800:focus {
  color: black !important; }

.text-yellow-900 {
  color: #261d01 !important; }

a.text-yellow-900:hover, a.text-yellow-900:focus {
  color: black !important; }

.text-gray-100 {
  color: #f4f3f1 !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #d2cec6 !important; }

.text-gray-200 {
  color: #e2e0dd !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #bfbab4 !important; }

.text-gray-300 {
  color: #c6c2bb !important; }

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #a39d91 !important; }

.text-gray-400 {
  color: #a9a398 !important; }

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #857d6f !important; }

.text-gray-500 {
  color: #8c8476 !important; }

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #635d53 !important; }

.text-gray-600 {
  color: #696359 !important; }

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #403c36 !important; }

.text-gray-700 {
  color: #46423b !important; }

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #1c1b18 !important; }

.text-gray-800 {
  color: #23211e !important; }

a.text-gray-800:hover, a.text-gray-800:focus {
  color: black !important; }

.text-gray-900 {
  color: #151412 !important; }

a.text-gray-900:hover, a.text-gray-900:focus {
  color: black !important; }

.text-gray-dark-100 {
  color: #ebebea !important; }

a.text-gray-dark-100:hover, a.text-gray-dark-100:focus {
  color: #c6c6c3 !important; }

.text-gray-dark-200 {
  color: #cdcdcb !important; }

a.text-gray-dark-200:hover, a.text-gray-dark-200:focus {
  color: #a8a8a4 !important; }

.text-gray-dark-300 {
  color: #9c9a97 !important; }

a.text-gray-dark-300:hover, a.text-gray-dark-300:focus {
  color: #767470 !important; }

.text-gray-dark-400 {
  color: #6a6863 !important; }

a.text-gray-dark-400:hover, a.text-gray-dark-400:focus {
  color: #42413e !important; }

.text-gray-dark-500 {
  color: #38352f !important; }

a.text-gray-dark-500:hover, a.text-gray-dark-500:focus {
  color: #0e0e0c !important; }

.text-gray-dark-600 {
  color: #2a2823 !important; }

a.text-gray-dark-600:hover, a.text-gray-dark-600:focus {
  color: black !important; }

.text-gray-dark-700 {
  color: #1c1b18 !important; }

a.text-gray-dark-700:hover, a.text-gray-dark-700:focus {
  color: black !important; }

.text-gray-dark-800 {
  color: #0e0d0c !important; }

a.text-gray-dark-800:hover, a.text-gray-dark-800:focus {
  color: black !important; }

.text-gray-dark-900 {
  color: #080807 !important; }

a.text-gray-dark-900:hover, a.text-gray-dark-900:focus {
  color: black !important; }

.text-primary-100 {
  color: #e6f2ff !important; }

a.text-primary-100:hover, a.text-primary-100:focus {
  color: #9acaff !important; }

.text-primary-200 {
  color: #bfdeff !important; }

a.text-primary-200:hover, a.text-primary-200:focus {
  color: #73b7ff !important; }

.text-primary-300 {
  color: #80bdff !important; }

a.text-primary-300:hover, a.text-primary-300:focus {
  color: #3495ff !important; }

.text-primary-400 {
  color: #409cff !important; }

a.text-primary-400:hover, a.text-primary-400:focus {
  color: #0075f3 !important; }

.text-primary-500 {
  color: #007bff !important; }

a.text-primary-500:hover, a.text-primary-500:focus {
  color: #0056b3 !important; }

.text-primary-600 {
  color: #005cbf !important; }

a.text-primary-600:hover, a.text-primary-600:focus {
  color: #003773 !important; }

.text-primary-700 {
  color: #003e80 !important; }

a.text-primary-700:hover, a.text-primary-700:focus {
  color: #001934 !important; }

.text-primary-800 {
  color: #001f40 !important; }

a.text-primary-800:hover, a.text-primary-800:focus {
  color: black !important; }

.text-primary-900 {
  color: #001226 !important; }

a.text-primary-900:hover, a.text-primary-900:focus {
  color: black !important; }

.text-success-100 {
  color: #eaf6ec !important; }

a.text-success-100:hover, a.text-success-100:focus {
  color: #b4dfbc !important; }

.text-success-200 {
  color: #c9e9d1 !important; }

a.text-success-200:hover, a.text-success-200:focus {
  color: #93d3a3 !important; }

.text-success-300 {
  color: #94d3a2 !important; }

a.text-success-300:hover, a.text-success-300:focus {
  color: #5ebd73 !important; }

.text-success-400 {
  color: #5ebd74 !important; }

a.text-success-400:hover, a.text-success-400:focus {
  color: #3c9250 !important; }

.text-success-500 {
  color: #28a745 !important; }

a.text-success-500:hover, a.text-success-500:focus {
  color: #19692c !important; }

.text-success-600 {
  color: #1e7d34 !important; }

a.text-success-600:hover, a.text-success-600:focus {
  color: #0f3f1a !important; }

.text-success-700 {
  color: #145423 !important; }

a.text-success-700:hover, a.text-success-700:focus {
  color: #051609 !important; }

.text-success-800 {
  color: #0a2a11 !important; }

a.text-success-800:hover, a.text-success-800:focus {
  color: black !important; }

.text-success-900 {
  color: #06190a !important; }

a.text-success-900:hover, a.text-success-900:focus {
  color: black !important; }

.text-info-100 {
  color: #e8f6f8 !important; }

a.text-info-100:hover, a.text-info-100:focus {
  color: #addfe6 !important; }

.text-info-200 {
  color: #c5e8ed !important; }

a.text-info-200:hover, a.text-info-200:focus {
  color: #8bd1db !important; }

.text-info-300 {
  color: #8bd1dc !important; }

a.text-info-300:hover, a.text-info-300:focus {
  color: #50baca !important; }

.text-info-400 {
  color: #51b9ca !important; }

a.text-info-400:hover, a.text-info-400:focus {
  color: #308f9e !important; }

.text-info-500 {
  color: #17a2b8 !important; }

a.text-info-500:hover, a.text-info-500:focus {
  color: #0f6674 !important; }

.text-info-600 {
  color: #117a8a !important; }

a.text-info-600:hover, a.text-info-600:focus {
  color: #093e46 !important; }

.text-info-700 {
  color: #0c515c !important; }

a.text-info-700:hover, a.text-info-700:focus {
  color: #031518 !important; }

.text-info-800 {
  color: #06292e !important; }

a.text-info-800:hover, a.text-info-800:focus {
  color: black !important; }

.text-info-900 {
  color: #03181c !important; }

a.text-info-900:hover, a.text-info-900:focus {
  color: black !important; }

.text-warning-100 {
  color: #fff9e6 !important; }

a.text-warning-100:hover, a.text-warning-100:focus {
  color: #ffe79a !important; }

.text-warning-200 {
  color: #fff0c1 !important; }

a.text-warning-200:hover, a.text-warning-200:focus {
  color: #ffdd75 !important; }

.text-warning-300 {
  color: #ffe083 !important; }

a.text-warning-300:hover, a.text-warning-300:focus {
  color: #ffcd37 !important; }

.text-warning-400 {
  color: #ffd145 !important; }

a.text-warning-400:hover, a.text-warning-400:focus {
  color: #f8ba00 !important; }

.text-warning-500 {
  color: #ffc107 !important; }

a.text-warning-500:hover, a.text-warning-500:focus {
  color: #ba8b00 !important; }

.text-warning-600 {
  color: #bf9105 !important; }

a.text-warning-600:hover, a.text-warning-600:focus {
  color: #745803 !important; }

.text-warning-700 {
  color: #806104 !important; }

a.text-warning-700:hover, a.text-warning-700:focus {
  color: #362902 !important; }

.text-warning-800 {
  color: #403002 !important; }

a.text-warning-800:hover, a.text-warning-800:focus {
  color: black !important; }

.text-warning-900 {
  color: #261d01 !important; }

a.text-warning-900:hover, a.text-warning-900:focus {
  color: black !important; }

.text-danger-100 {
  color: #fcebec !important; }

a.text-danger-100:hover, a.text-danger-100:focus {
  color: #f2a8ad !important; }

.text-danger-200 {
  color: #f6cdd1 !important; }

a.text-danger-200:hover, a.text-danger-200:focus {
  color: #ea8c95 !important; }

.text-danger-300 {
  color: #ee9aa2 !important; }

a.text-danger-300:hover, a.text-danger-300:focus {
  color: #e35966 !important; }

.text-danger-400 {
  color: #e56874 !important; }

a.text-danger-400:hover, a.text-danger-400:focus {
  color: #da2738 !important; }

.text-danger-500 {
  color: #dc3545 !important; }

a.text-danger-500:hover, a.text-danger-500:focus {
  color: #a71d2a !important; }

.text-danger-600 {
  color: #a52834 !important; }

a.text-danger-600:hover, a.text-danger-600:focus {
  color: #671921 !important; }

.text-danger-700 {
  color: #6e1b23 !important; }

a.text-danger-700:hover, a.text-danger-700:focus {
  color: #310c0f !important; }

.text-danger-800 {
  color: #370d11 !important; }

a.text-danger-800:hover, a.text-danger-800:focus {
  color: black !important; }

.text-danger-900 {
  color: #21080a !important; }

a.text-danger-900:hover, a.text-danger-900:focus {
  color: black !important; }

/* background colors*/
.bg-blue {
  background-color: #0e73cc !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #0b589c !important; }

.bg-red {
  background-color: #fc4848 !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #fb1616 !important; }

.bg-pink {
  background-color: #e91e63 !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #c1134e !important; }

.bg-yellow {
  background-color: #ffc107 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #d39e00 !important; }

.bg-gray, .numbered > dt:before,
.numbered > li:before {
  background-color: #8c8476 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #716a5e !important; }

.bg-gray-dark {
  background-color: #38352f !important; }

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #1c1b18 !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-blue-100 {
  background-color: #e7f1fa !important; }

a.bg-blue-100:hover, a.bg-blue-100:focus,
button.bg-blue-100:hover,
button.bg-blue-100:focus {
  background-color: #bdd8f1 !important; }

.bg-blue-200 {
  background-color: #c3dcf2 !important; }

a.bg-blue-200:hover, a.bg-blue-200:focus,
button.bg-blue-200:hover,
button.bg-blue-200:focus {
  background-color: #99c4e9 !important; }

.bg-blue-300 {
  background-color: #87b9e6 !important; }

a.bg-blue-300:hover, a.bg-blue-300:focus,
button.bg-blue-300:hover,
button.bg-blue-300:focus {
  background-color: #5da0dd !important; }

.bg-blue-400 {
  background-color: #4a96d9 !important; }

a.bg-blue-400:hover, a.bg-blue-400:focus,
button.bg-blue-400:hover,
button.bg-blue-400:focus {
  background-color: #2a7dc6 !important; }

.bg-blue-500 {
  background-color: #0e73cc !important; }

a.bg-blue-500:hover, a.bg-blue-500:focus,
button.bg-blue-500:hover,
button.bg-blue-500:focus {
  background-color: #0b589c !important; }

.bg-blue-600 {
  background-color: #0b5699 !important; }

a.bg-blue-600:hover, a.bg-blue-600:focus,
button.bg-blue-600:hover,
button.bg-blue-600:focus {
  background-color: #083b69 !important; }

.bg-blue-700 {
  background-color: #073a66 !important; }

a.bg-blue-700:hover, a.bg-blue-700:focus,
button.bg-blue-700:hover,
button.bg-blue-700:focus {
  background-color: #041f36 !important; }

.bg-blue-800 {
  background-color: #041d33 !important; }

a.bg-blue-800:hover, a.bg-blue-800:focus,
button.bg-blue-800:hover,
button.bg-blue-800:focus {
  background-color: #000204 !important; }

.bg-blue-900 {
  background-color: #02111f !important; }

a.bg-blue-900:hover, a.bg-blue-900:focus,
button.bg-blue-900:hover,
button.bg-blue-900:focus {
  background-color: black !important; }

.bg-red-100 {
  background-color: #ffeded !important; }

a.bg-red-100:hover, a.bg-red-100:focus,
button.bg-red-100:hover,
button.bg-red-100:focus {
  background-color: #ffbaba !important; }

.bg-red-200 {
  background-color: #fed1d1 !important; }

a.bg-red-200:hover, a.bg-red-200:focus,
button.bg-red-200:hover,
button.bg-red-200:focus {
  background-color: #fd9f9f !important; }

.bg-red-300 {
  background-color: #fea4a4 !important; }

a.bg-red-300:hover, a.bg-red-300:focus,
button.bg-red-300:hover,
button.bg-red-300:focus {
  background-color: #fd7272 !important; }

.bg-red-400 {
  background-color: #fd7676 !important; }

a.bg-red-400:hover, a.bg-red-400:focus,
button.bg-red-400:hover,
button.bg-red-400:focus {
  background-color: #fc4444 !important; }

.bg-red-500 {
  background-color: #fc4848 !important; }

a.bg-red-500:hover, a.bg-red-500:focus,
button.bg-red-500:hover,
button.bg-red-500:focus {
  background-color: #fb1616 !important; }

.bg-red-600 {
  background-color: #bd3636 !important; }

a.bg-red-600:hover, a.bg-red-600:focus,
button.bg-red-600:hover,
button.bg-red-600:focus {
  background-color: #952b2b !important; }

.bg-red-700 {
  background-color: #7e2424 !important; }

a.bg-red-700:hover, a.bg-red-700:focus,
button.bg-red-700:hover,
button.bg-red-700:focus {
  background-color: #561919 !important; }

.bg-red-800 {
  background-color: #3f1212 !important; }

a.bg-red-800:hover, a.bg-red-800:focus,
button.bg-red-800:hover,
button.bg-red-800:focus {
  background-color: #170707 !important; }

.bg-red-900 {
  background-color: #260b0b !important; }

a.bg-red-900:hover, a.bg-red-900:focus,
button.bg-red-900:hover,
button.bg-red-900:focus {
  background-color: black !important; }

.bg-pink-100 {
  background-color: #fde9ef !important; }

a.bg-pink-100:hover, a.bg-pink-100:focus,
button.bg-pink-100:hover,
button.bg-pink-100:focus {
  background-color: #f9bacd !important; }

.bg-pink-200 {
  background-color: #fac7d8 !important; }

a.bg-pink-200:hover, a.bg-pink-200:focus,
button.bg-pink-200:hover,
button.bg-pink-200:focus {
  background-color: #f698b7 !important; }

.bg-pink-300 {
  background-color: #f48fb1 !important; }

a.bg-pink-300:hover, a.bg-pink-300:focus,
button.bg-pink-300:hover,
button.bg-pink-300:focus {
  background-color: #ef6191 !important; }

.bg-pink-400 {
  background-color: #ef568a !important; }

a.bg-pink-400:hover, a.bg-pink-400:focus,
button.bg-pink-400:hover,
button.bg-pink-400:focus {
  background-color: #eb276a !important; }

.bg-pink-500 {
  background-color: #e91e63 !important; }

a.bg-pink-500:hover, a.bg-pink-500:focus,
button.bg-pink-500:hover,
button.bg-pink-500:focus {
  background-color: #c1134e !important; }

.bg-pink-600 {
  background-color: #af174a !important; }

a.bg-pink-600:hover, a.bg-pink-600:focus,
button.bg-pink-600:hover,
button.bg-pink-600:focus {
  background-color: #821137 !important; }

.bg-pink-700 {
  background-color: #750f32 !important; }

a.bg-pink-700:hover, a.bg-pink-700:focus,
button.bg-pink-700:hover,
button.bg-pink-700:focus {
  background-color: #48091f !important; }

.bg-pink-800 {
  background-color: #3a0819 !important; }

a.bg-pink-800:hover, a.bg-pink-800:focus,
button.bg-pink-800:hover,
button.bg-pink-800:focus {
  background-color: #0d0206 !important; }

.bg-pink-900 {
  background-color: #23050f !important; }

a.bg-pink-900:hover, a.bg-pink-900:focus,
button.bg-pink-900:hover,
button.bg-pink-900:focus {
  background-color: black !important; }

.bg-yellow-100 {
  background-color: #fff9e6 !important; }

a.bg-yellow-100:hover, a.bg-yellow-100:focus,
button.bg-yellow-100:hover,
button.bg-yellow-100:focus {
  background-color: #ffedb3 !important; }

.bg-yellow-200 {
  background-color: #fff0c1 !important; }

a.bg-yellow-200:hover, a.bg-yellow-200:focus,
button.bg-yellow-200:hover,
button.bg-yellow-200:focus {
  background-color: #ffe48e !important; }

.bg-yellow-300 {
  background-color: #ffe083 !important; }

a.bg-yellow-300:hover, a.bg-yellow-300:focus,
button.bg-yellow-300:hover,
button.bg-yellow-300:focus {
  background-color: #ffd350 !important; }

.bg-yellow-400 {
  background-color: #ffd145 !important; }

a.bg-yellow-400:hover, a.bg-yellow-400:focus,
button.bg-yellow-400:hover,
button.bg-yellow-400:focus {
  background-color: #ffc412 !important; }

.bg-yellow-500 {
  background-color: #ffc107 !important; }

a.bg-yellow-500:hover, a.bg-yellow-500:focus,
button.bg-yellow-500:hover,
button.bg-yellow-500:focus {
  background-color: #d39e00 !important; }

.bg-yellow-600 {
  background-color: #bf9105 !important; }

a.bg-yellow-600:hover, a.bg-yellow-600:focus,
button.bg-yellow-600:hover,
button.bg-yellow-600:focus {
  background-color: #8d6b04 !important; }

.bg-yellow-700 {
  background-color: #806104 !important; }

a.bg-yellow-700:hover, a.bg-yellow-700:focus,
button.bg-yellow-700:hover,
button.bg-yellow-700:focus {
  background-color: #4f3c02 !important; }

.bg-yellow-800 {
  background-color: #403002 !important; }

a.bg-yellow-800:hover, a.bg-yellow-800:focus,
button.bg-yellow-800:hover,
button.bg-yellow-800:focus {
  background-color: #0f0b00 !important; }

.bg-yellow-900 {
  background-color: #261d01 !important; }

a.bg-yellow-900:hover, a.bg-yellow-900:focus,
button.bg-yellow-900:hover,
button.bg-yellow-900:focus {
  background-color: black !important; }

.bg-gray-100, .badge-light {
  background-color: #f4f3f1 !important; }

a.bg-gray-100:hover, a.badge-light:hover, a.bg-gray-100:focus, a.badge-light:focus,
button.bg-gray-100:hover,
button.badge-light:hover,
button.bg-gray-100:focus,
button.badge-light:focus {
  background-color: #dedbd4 !important; }

.bg-gray-200 {
  background-color: #e2e0dd !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cbc7c1 !important; }

.bg-gray-300 {
  background-color: #c6c2bb !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #afa99f !important; }

.bg-gray-400 {
  background-color: #a9a398 !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #928a7c !important; }

.bg-gray-500 {
  background-color: #8c8476 !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #716a5e !important; }

.bg-gray-600 {
  background-color: #696359 !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #4d4942 !important; }

.bg-gray-700 {
  background-color: #46423b !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #2a2824 !important; }

.bg-gray-800 {
  background-color: #23211e !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #080706 !important; }

.bg-gray-900 {
  background-color: #151412 !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: black !important; }

.bg-gray-dark-100 {
  background-color: #ebebea !important; }

a.bg-gray-dark-100:hover, a.bg-gray-dark-100:focus,
button.bg-gray-dark-100:hover,
button.bg-gray-dark-100:focus {
  background-color: #d2d2d0 !important; }

.bg-gray-dark-200 {
  background-color: #cdcdcb !important; }

a.bg-gray-dark-200:hover, a.bg-gray-dark-200:focus,
button.bg-gray-dark-200:hover,
button.bg-gray-dark-200:focus {
  background-color: #b4b4b1 !important; }

.bg-gray-dark-300 {
  background-color: #9c9a97 !important; }

a.bg-gray-dark-300:hover, a.bg-gray-dark-300:focus,
button.bg-gray-dark-300:hover,
button.bg-gray-dark-300:focus {
  background-color: #83817d !important; }

.bg-gray-dark-400 {
  background-color: #6a6863 !important; }

a.bg-gray-dark-400:hover, a.bg-gray-dark-400:focus,
button.bg-gray-dark-400:hover,
button.bg-gray-dark-400:focus {
  background-color: #504e4a !important; }

.bg-gray-dark-500 {
  background-color: #38352f !important; }

a.bg-gray-dark-500:hover, a.bg-gray-dark-500:focus,
button.bg-gray-dark-500:hover,
button.bg-gray-dark-500:focus {
  background-color: #1c1b18 !important; }

.bg-gray-dark-600 {
  background-color: #2a2823 !important; }

a.bg-gray-dark-600:hover, a.bg-gray-dark-600:focus,
button.bg-gray-dark-600:hover,
button.bg-gray-dark-600:focus {
  background-color: #0e0e0c !important; }

.bg-gray-dark-700 {
  background-color: #1c1b18 !important; }

a.bg-gray-dark-700:hover, a.bg-gray-dark-700:focus,
button.bg-gray-dark-700:hover,
button.bg-gray-dark-700:focus {
  background-color: #010100 !important; }

.bg-gray-dark-800 {
  background-color: #0e0d0c !important; }

a.bg-gray-dark-800:hover, a.bg-gray-dark-800:focus,
button.bg-gray-dark-800:hover,
button.bg-gray-dark-800:focus {
  background-color: black !important; }

.bg-gray-dark-900 {
  background-color: #080807 !important; }

a.bg-gray-dark-900:hover, a.bg-gray-dark-900:focus,
button.bg-gray-dark-900:hover,
button.bg-gray-dark-900:focus {
  background-color: black !important; }

.bg-primary-100 {
  background-color: #e6f2ff !important; }

a.bg-primary-100:hover, a.bg-primary-100:focus,
button.bg-primary-100:hover,
button.bg-primary-100:focus {
  background-color: #b3d7ff !important; }

.bg-primary-200 {
  background-color: #bfdeff !important; }

a.bg-primary-200:hover, a.bg-primary-200:focus,
button.bg-primary-200:hover,
button.bg-primary-200:focus {
  background-color: #8cc4ff !important; }

.bg-primary-300 {
  background-color: #80bdff !important; }

a.bg-primary-300:hover, a.bg-primary-300:focus,
button.bg-primary-300:hover,
button.bg-primary-300:focus {
  background-color: #4da2ff !important; }

.bg-primary-400 {
  background-color: #409cff !important; }

a.bg-primary-400:hover, a.bg-primary-400:focus,
button.bg-primary-400:hover,
button.bg-primary-400:focus {
  background-color: #0d82ff !important; }

.bg-primary-500 {
  background-color: #007bff !important; }

a.bg-primary-500:hover, a.bg-primary-500:focus,
button.bg-primary-500:hover,
button.bg-primary-500:focus {
  background-color: #0062cc !important; }

.bg-primary-600 {
  background-color: #005cbf !important; }

a.bg-primary-600:hover, a.bg-primary-600:focus,
button.bg-primary-600:hover,
button.bg-primary-600:focus {
  background-color: #00438c !important; }

.bg-primary-700 {
  background-color: #003e80 !important; }

a.bg-primary-700:hover, a.bg-primary-700:focus,
button.bg-primary-700:hover,
button.bg-primary-700:focus {
  background-color: #00254d !important; }

.bg-primary-800 {
  background-color: #001f40 !important; }

a.bg-primary-800:hover, a.bg-primary-800:focus,
button.bg-primary-800:hover,
button.bg-primary-800:focus {
  background-color: #00060d !important; }

.bg-primary-900 {
  background-color: #001226 !important; }

a.bg-primary-900:hover, a.bg-primary-900:focus,
button.bg-primary-900:hover,
button.bg-primary-900:focus {
  background-color: black !important; }

.bg-success-100 {
  background-color: #eaf6ec !important; }

a.bg-success-100:hover, a.bg-success-100:focus,
button.bg-success-100:hover,
button.bg-success-100:focus {
  background-color: #c6e7cc !important; }

.bg-success-200 {
  background-color: #c9e9d1 !important; }

a.bg-success-200:hover, a.bg-success-200:focus,
button.bg-success-200:hover,
button.bg-success-200:focus {
  background-color: #a5dab2 !important; }

.bg-success-300 {
  background-color: #94d3a2 !important; }

a.bg-success-300:hover, a.bg-success-300:focus,
button.bg-success-300:hover,
button.bg-success-300:focus {
  background-color: #70c483 !important; }

.bg-success-400 {
  background-color: #5ebd74 !important; }

a.bg-success-400:hover, a.bg-success-400:focus,
button.bg-success-400:hover,
button.bg-success-400:focus {
  background-color: #43a55a !important; }

.bg-success-500 {
  background-color: #28a745 !important; }

a.bg-success-500:hover, a.bg-success-500:focus,
button.bg-success-500:hover,
button.bg-success-500:focus {
  background-color: #1e7e34 !important; }

.bg-success-600 {
  background-color: #1e7d34 !important; }

a.bg-success-600:hover, a.bg-success-600:focus,
button.bg-success-600:hover,
button.bg-success-600:focus {
  background-color: #145423 !important; }

.bg-success-700 {
  background-color: #145423 !important; }

a.bg-success-700:hover, a.bg-success-700:focus,
button.bg-success-700:hover,
button.bg-success-700:focus {
  background-color: #0a2b12 !important; }

.bg-success-800 {
  background-color: #0a2a11 !important; }

a.bg-success-800:hover, a.bg-success-800:focus,
button.bg-success-800:hover,
button.bg-success-800:focus {
  background-color: #000100 !important; }

.bg-success-900 {
  background-color: #06190a !important; }

a.bg-success-900:hover, a.bg-success-900:focus,
button.bg-success-900:hover,
button.bg-success-900:focus {
  background-color: black !important; }

.bg-info-100 {
  background-color: #e8f6f8 !important; }

a.bg-info-100:hover, a.bg-info-100:focus,
button.bg-info-100:hover,
button.bg-info-100:focus {
  background-color: #c1e7ec !important; }

.bg-info-200 {
  background-color: #c5e8ed !important; }

a.bg-info-200:hover, a.bg-info-200:focus,
button.bg-info-200:hover,
button.bg-info-200:focus {
  background-color: #9ed9e1 !important; }

.bg-info-300 {
  background-color: #8bd1dc !important; }

a.bg-info-300:hover, a.bg-info-300:focus,
button.bg-info-300:hover,
button.bg-info-300:focus {
  background-color: #64c1d0 !important; }

.bg-info-400 {
  background-color: #51b9ca !important; }

a.bg-info-400:hover, a.bg-info-400:focus,
button.bg-info-400:hover,
button.bg-info-400:focus {
  background-color: #36a0b2 !important; }

.bg-info-500 {
  background-color: #17a2b8 !important; }

a.bg-info-500:hover, a.bg-info-500:focus,
button.bg-info-500:hover,
button.bg-info-500:focus {
  background-color: #117a8b !important; }

.bg-info-600 {
  background-color: #117a8a !important; }

a.bg-info-600:hover, a.bg-info-600:focus,
button.bg-info-600:hover,
button.bg-info-600:focus {
  background-color: #0b525d !important; }

.bg-info-700 {
  background-color: #0c515c !important; }

a.bg-info-700:hover, a.bg-info-700:focus,
button.bg-info-700:hover,
button.bg-info-700:focus {
  background-color: #06292f !important; }

.bg-info-800 {
  background-color: #06292e !important; }

a.bg-info-800:hover, a.bg-info-800:focus,
button.bg-info-800:hover,
button.bg-info-800:focus {
  background-color: #000101 !important; }

.bg-info-900 {
  background-color: #03181c !important; }

a.bg-info-900:hover, a.bg-info-900:focus,
button.bg-info-900:hover,
button.bg-info-900:focus {
  background-color: black !important; }

.bg-warning-100 {
  background-color: #fff9e6 !important; }

a.bg-warning-100:hover, a.bg-warning-100:focus,
button.bg-warning-100:hover,
button.bg-warning-100:focus {
  background-color: #ffedb3 !important; }

.bg-warning-200 {
  background-color: #fff0c1 !important; }

a.bg-warning-200:hover, a.bg-warning-200:focus,
button.bg-warning-200:hover,
button.bg-warning-200:focus {
  background-color: #ffe48e !important; }

.bg-warning-300 {
  background-color: #ffe083 !important; }

a.bg-warning-300:hover, a.bg-warning-300:focus,
button.bg-warning-300:hover,
button.bg-warning-300:focus {
  background-color: #ffd350 !important; }

.bg-warning-400 {
  background-color: #ffd145 !important; }

a.bg-warning-400:hover, a.bg-warning-400:focus,
button.bg-warning-400:hover,
button.bg-warning-400:focus {
  background-color: #ffc412 !important; }

.bg-warning-500 {
  background-color: #ffc107 !important; }

a.bg-warning-500:hover, a.bg-warning-500:focus,
button.bg-warning-500:hover,
button.bg-warning-500:focus {
  background-color: #d39e00 !important; }

.bg-warning-600 {
  background-color: #bf9105 !important; }

a.bg-warning-600:hover, a.bg-warning-600:focus,
button.bg-warning-600:hover,
button.bg-warning-600:focus {
  background-color: #8d6b04 !important; }

.bg-warning-700 {
  background-color: #806104 !important; }

a.bg-warning-700:hover, a.bg-warning-700:focus,
button.bg-warning-700:hover,
button.bg-warning-700:focus {
  background-color: #4f3c02 !important; }

.bg-warning-800 {
  background-color: #403002 !important; }

a.bg-warning-800:hover, a.bg-warning-800:focus,
button.bg-warning-800:hover,
button.bg-warning-800:focus {
  background-color: #0f0b00 !important; }

.bg-warning-900 {
  background-color: #261d01 !important; }

a.bg-warning-900:hover, a.bg-warning-900:focus,
button.bg-warning-900:hover,
button.bg-warning-900:focus {
  background-color: black !important; }

.bg-danger-100 {
  background-color: #fcebec !important; }

a.bg-danger-100:hover, a.bg-danger-100:focus,
button.bg-danger-100:hover,
button.bg-danger-100:focus {
  background-color: #f5bfc2 !important; }

.bg-danger-200 {
  background-color: #f6cdd1 !important; }

a.bg-danger-200:hover, a.bg-danger-200:focus,
button.bg-danger-200:hover,
button.bg-danger-200:focus {
  background-color: #eea2a9 !important; }

.bg-danger-300 {
  background-color: #ee9aa2 !important; }

a.bg-danger-300:hover, a.bg-danger-300:focus,
button.bg-danger-300:hover,
button.bg-danger-300:focus {
  background-color: #e76e7a !important; }

.bg-danger-400 {
  background-color: #e56874 !important; }

a.bg-danger-400:hover, a.bg-danger-400:focus,
button.bg-danger-400:hover,
button.bg-danger-400:focus {
  background-color: #de3c4c !important; }

.bg-danger-500 {
  background-color: #dc3545 !important; }

a.bg-danger-500:hover, a.bg-danger-500:focus,
button.bg-danger-500:hover,
button.bg-danger-500:focus {
  background-color: #bd2130 !important; }

.bg-danger-600 {
  background-color: #a52834 !important; }

a.bg-danger-600:hover, a.bg-danger-600:focus,
button.bg-danger-600:hover,
button.bg-danger-600:focus {
  background-color: #7c1e27 !important; }

.bg-danger-700 {
  background-color: #6e1b23 !important; }

a.bg-danger-700:hover, a.bg-danger-700:focus,
button.bg-danger-700:hover,
button.bg-danger-700:focus {
  background-color: #451116 !important; }

.bg-danger-800 {
  background-color: #370d11 !important; }

a.bg-danger-800:hover, a.bg-danger-800:focus,
button.bg-danger-800:hover,
button.bg-danger-800:focus {
  background-color: #0e0304 !important; }

.bg-danger-900 {
  background-color: #21080a !important; }

a.bg-danger-900:hover, a.bg-danger-900:focus,
button.bg-danger-900:hover,
button.bg-danger-900:focus {
  background-color: black !important; }

/* background text contrast*/
.bg-blue {
  color: #fff; }

.bg-red {
  color: #fff; }

.bg-pink {
  color: #fff; }

.bg-yellow {
  color: #212529; }

.bg-gray, .numbered > dt:before,
.numbered > li:before {
  color: #fff; }

.bg-gray-dark {
  color: #fff; }

.bg-primary {
  color: #fff; }

.bg-success {
  color: #fff; }

.bg-info {
  color: #fff; }

.bg-warning {
  color: #212529; }

.bg-danger {
  color: #fff; }

.bg-blue-100 {
  color: #212529; }

.bg-blue-200 {
  color: #212529; }

.bg-blue-300 {
  color: #212529; }

.bg-blue-400 {
  color: #fff; }

.bg-blue-500 {
  color: #fff; }

.bg-blue-600 {
  color: #fff; }

.bg-blue-700 {
  color: #fff; }

.bg-blue-800 {
  color: #fff; }

.bg-blue-900 {
  color: #fff; }

.bg-red-100 {
  color: #212529; }

.bg-red-200 {
  color: #212529; }

.bg-red-300 {
  color: #212529; }

.bg-red-400 {
  color: #212529; }

.bg-red-500 {
  color: #fff; }

.bg-red-600 {
  color: #fff; }

.bg-red-700 {
  color: #fff; }

.bg-red-800 {
  color: #fff; }

.bg-red-900 {
  color: #fff; }

.bg-pink-100 {
  color: #212529; }

.bg-pink-200 {
  color: #212529; }

.bg-pink-300 {
  color: #212529; }

.bg-pink-400 {
  color: #fff; }

.bg-pink-500 {
  color: #fff; }

.bg-pink-600 {
  color: #fff; }

.bg-pink-700 {
  color: #fff; }

.bg-pink-800 {
  color: #fff; }

.bg-pink-900 {
  color: #fff; }

.bg-yellow-100 {
  color: #212529; }

.bg-yellow-200 {
  color: #212529; }

.bg-yellow-300 {
  color: #212529; }

.bg-yellow-400 {
  color: #212529; }

.bg-yellow-500 {
  color: #212529; }

.bg-yellow-600 {
  color: #fff; }

.bg-yellow-700 {
  color: #fff; }

.bg-yellow-800 {
  color: #fff; }

.bg-yellow-900 {
  color: #fff; }

.bg-gray-100, .badge-light {
  color: #212529; }

.bg-gray-200 {
  color: #212529; }

.bg-gray-300 {
  color: #212529; }

.bg-gray-400 {
  color: #212529; }

.bg-gray-500 {
  color: #fff; }

.bg-gray-600 {
  color: #fff; }

.bg-gray-700 {
  color: #fff; }

.bg-gray-800 {
  color: #fff; }

.bg-gray-900 {
  color: #fff; }

.bg-gray-dark-100 {
  color: #212529; }

.bg-gray-dark-200 {
  color: #212529; }

.bg-gray-dark-300 {
  color: #212529; }

.bg-gray-dark-400 {
  color: #fff; }

.bg-gray-dark-500 {
  color: #fff; }

.bg-gray-dark-600 {
  color: #fff; }

.bg-gray-dark-700 {
  color: #fff; }

.bg-gray-dark-800 {
  color: #fff; }

.bg-gray-dark-900 {
  color: #fff; }

.bg-primary-100 {
  color: #212529; }

.bg-primary-200 {
  color: #212529; }

.bg-primary-300 {
  color: #212529; }

.bg-primary-400 {
  color: #fff; }

.bg-primary-500 {
  color: #fff; }

.bg-primary-600 {
  color: #fff; }

.bg-primary-700 {
  color: #fff; }

.bg-primary-800 {
  color: #fff; }

.bg-primary-900 {
  color: #fff; }

.bg-success-100 {
  color: #212529; }

.bg-success-200 {
  color: #212529; }

.bg-success-300 {
  color: #212529; }

.bg-success-400 {
  color: #212529; }

.bg-success-500 {
  color: #fff; }

.bg-success-600 {
  color: #fff; }

.bg-success-700 {
  color: #fff; }

.bg-success-800 {
  color: #fff; }

.bg-success-900 {
  color: #fff; }

.bg-info-100 {
  color: #212529; }

.bg-info-200 {
  color: #212529; }

.bg-info-300 {
  color: #212529; }

.bg-info-400 {
  color: #212529; }

.bg-info-500 {
  color: #fff; }

.bg-info-600 {
  color: #fff; }

.bg-info-700 {
  color: #fff; }

.bg-info-800 {
  color: #fff; }

.bg-info-900 {
  color: #fff; }

.bg-warning-100 {
  color: #212529; }

.bg-warning-200 {
  color: #212529; }

.bg-warning-300 {
  color: #212529; }

.bg-warning-400 {
  color: #212529; }

.bg-warning-500 {
  color: #212529; }

.bg-warning-600 {
  color: #fff; }

.bg-warning-700 {
  color: #fff; }

.bg-warning-800 {
  color: #fff; }

.bg-warning-900 {
  color: #fff; }

.bg-danger-100 {
  color: #212529; }

.bg-danger-200 {
  color: #212529; }

.bg-danger-300 {
  color: #212529; }

.bg-danger-400 {
  color: #fff; }

.bg-danger-500 {
  color: #fff; }

.bg-danger-600 {
  color: #fff; }

.bg-danger-700 {
  color: #fff; }

.bg-danger-800 {
  color: #fff; }

.bg-danger-900 {
  color: #fff; }

/* border colors*/
.border-blue {
  border-color: #0e73cc !important; }

.border-red {
  border-color: #fc4848 !important; }

.border-pink {
  border-color: #e91e63 !important; }

.border-yellow {
  border-color: #ffc107 !important; }

.border-gray {
  border-color: #8c8476 !important; }

.border-gray-dark {
  border-color: #38352f !important; }

.border-primary {
  border-color: #007bff !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-blue-100 {
  border-color: #e7f1fa !important; }

.border-blue-200 {
  border-color: #c3dcf2 !important; }

.border-blue-300 {
  border-color: #87b9e6 !important; }

.border-blue-400 {
  border-color: #4a96d9 !important; }

.border-blue-500 {
  border-color: #0e73cc !important; }

.border-blue-600 {
  border-color: #0b5699 !important; }

.border-blue-700 {
  border-color: #073a66 !important; }

.border-blue-800 {
  border-color: #041d33 !important; }

.border-blue-900 {
  border-color: #02111f !important; }

.border-red-100 {
  border-color: #ffeded !important; }

.border-red-200 {
  border-color: #fed1d1 !important; }

.border-red-300 {
  border-color: #fea4a4 !important; }

.border-red-400 {
  border-color: #fd7676 !important; }

.border-red-500 {
  border-color: #fc4848 !important; }

.border-red-600 {
  border-color: #bd3636 !important; }

.border-red-700 {
  border-color: #7e2424 !important; }

.border-red-800 {
  border-color: #3f1212 !important; }

.border-red-900 {
  border-color: #260b0b !important; }

.border-pink-100 {
  border-color: #fde9ef !important; }

.border-pink-200 {
  border-color: #fac7d8 !important; }

.border-pink-300 {
  border-color: #f48fb1 !important; }

.border-pink-400 {
  border-color: #ef568a !important; }

.border-pink-500 {
  border-color: #e91e63 !important; }

.border-pink-600 {
  border-color: #af174a !important; }

.border-pink-700 {
  border-color: #750f32 !important; }

.border-pink-800 {
  border-color: #3a0819 !important; }

.border-pink-900 {
  border-color: #23050f !important; }

.border-yellow-100 {
  border-color: #fff9e6 !important; }

.border-yellow-200 {
  border-color: #fff0c1 !important; }

.border-yellow-300 {
  border-color: #ffe083 !important; }

.border-yellow-400 {
  border-color: #ffd145 !important; }

.border-yellow-500 {
  border-color: #ffc107 !important; }

.border-yellow-600 {
  border-color: #bf9105 !important; }

.border-yellow-700 {
  border-color: #806104 !important; }

.border-yellow-800 {
  border-color: #403002 !important; }

.border-yellow-900 {
  border-color: #261d01 !important; }

.border-gray-100 {
  border-color: #f4f3f1 !important; }

.border-gray-200 {
  border-color: #e2e0dd !important; }

.border-gray-300 {
  border-color: #c6c2bb !important; }

.border-gray-400 {
  border-color: #a9a398 !important; }

.border-gray-500 {
  border-color: #8c8476 !important; }

.border-gray-600 {
  border-color: #696359 !important; }

.border-gray-700 {
  border-color: #46423b !important; }

.border-gray-800 {
  border-color: #23211e !important; }

.border-gray-900 {
  border-color: #151412 !important; }

.border-gray-dark-100 {
  border-color: #ebebea !important; }

.border-gray-dark-200 {
  border-color: #cdcdcb !important; }

.border-gray-dark-300 {
  border-color: #9c9a97 !important; }

.border-gray-dark-400 {
  border-color: #6a6863 !important; }

.border-gray-dark-500 {
  border-color: #38352f !important; }

.border-gray-dark-600 {
  border-color: #2a2823 !important; }

.border-gray-dark-700 {
  border-color: #1c1b18 !important; }

.border-gray-dark-800 {
  border-color: #0e0d0c !important; }

.border-gray-dark-900 {
  border-color: #080807 !important; }

.border-primary-100 {
  border-color: #e6f2ff !important; }

.border-primary-200 {
  border-color: #bfdeff !important; }

.border-primary-300 {
  border-color: #80bdff !important; }

.border-primary-400 {
  border-color: #409cff !important; }

.border-primary-500 {
  border-color: #007bff !important; }

.border-primary-600 {
  border-color: #005cbf !important; }

.border-primary-700 {
  border-color: #003e80 !important; }

.border-primary-800 {
  border-color: #001f40 !important; }

.border-primary-900 {
  border-color: #001226 !important; }

.border-success-100 {
  border-color: #eaf6ec !important; }

.border-success-200 {
  border-color: #c9e9d1 !important; }

.border-success-300 {
  border-color: #94d3a2 !important; }

.border-success-400 {
  border-color: #5ebd74 !important; }

.border-success-500 {
  border-color: #28a745 !important; }

.border-success-600 {
  border-color: #1e7d34 !important; }

.border-success-700 {
  border-color: #145423 !important; }

.border-success-800 {
  border-color: #0a2a11 !important; }

.border-success-900 {
  border-color: #06190a !important; }

.border-info-100 {
  border-color: #e8f6f8 !important; }

.border-info-200 {
  border-color: #c5e8ed !important; }

.border-info-300 {
  border-color: #8bd1dc !important; }

.border-info-400 {
  border-color: #51b9ca !important; }

.border-info-500 {
  border-color: #17a2b8 !important; }

.border-info-600 {
  border-color: #117a8a !important; }

.border-info-700 {
  border-color: #0c515c !important; }

.border-info-800 {
  border-color: #06292e !important; }

.border-info-900 {
  border-color: #03181c !important; }

.border-warning-100 {
  border-color: #fff9e6 !important; }

.border-warning-200 {
  border-color: #fff0c1 !important; }

.border-warning-300 {
  border-color: #ffe083 !important; }

.border-warning-400 {
  border-color: #ffd145 !important; }

.border-warning-500 {
  border-color: #ffc107 !important; }

.border-warning-600 {
  border-color: #bf9105 !important; }

.border-warning-700 {
  border-color: #806104 !important; }

.border-warning-800 {
  border-color: #403002 !important; }

.border-warning-900 {
  border-color: #261d01 !important; }

.border-danger-100 {
  border-color: #fcebec !important; }

.border-danger-200 {
  border-color: #f6cdd1 !important; }

.border-danger-300 {
  border-color: #ee9aa2 !important; }

.border-danger-400 {
  border-color: #e56874 !important; }

.border-danger-500 {
  border-color: #dc3545 !important; }

.border-danger-600 {
  border-color: #a52834 !important; }

.border-danger-700 {
  border-color: #6e1b23 !important; }

.border-danger-800 {
  border-color: #370d11 !important; }

.border-danger-900 {
  border-color: #21080a !important; }

/* bootstrap variables*/
/* components*/
/**
 *
 * Custom variables
 *
 */
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0e73cc !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0b589c !important; }

.bg-secondary {
  background-color: #696359 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #4d4942 !important; }

.bg-success {
  background-color: #0f9e7b !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0b6f57 !important; }

.bg-info {
  background-color: #00c2db !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0095a8 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #fc4848 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #fb1616 !important; }

.bg-light {
  background-color: #f4f3f1 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dedbd4 !important; }

.bg-dark {
  background-color: #23211e !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #080706 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0e73cc !important; }

.border-secondary {
  border-color: #696359 !important; }

.border-success {
  border-color: #0f9e7b !important; }

.border-info {
  border-color: #00c2db !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #fc4848 !important; }

.border-light {
  border-color: #f4f3f1 !important; }

.border-dark {
  border-color: #23211e !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block, .btn .icon-circle {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1,
.file-path,
.var-type,
.var-value {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1,
.file-path,
.var-type,
.var-value {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3, h4,
.h4,
h5,
.h5,
h6,
.h6, ol li,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4, h3,
.h3,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5, h2, .has-separator,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 6.25rem !important; }

.mt-6,
.my-6 {
  margin-top: 6.25rem !important; }

.mr-6,
.mx-6 {
  margin-right: 6.25rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 6.25rem !important; }

.ml-6,
.mx-6 {
  margin-left: 6.25rem !important; }

.m-7 {
  margin: 12.5rem !important; }

.mt-7,
.my-7 {
  margin-top: 12.5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 12.5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 12.5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 12.5rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0,
pre > code[class*='language'] {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0,
pre > code[class*='language'] {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1,
code[class*='language'] {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1,
code[class*='language'] {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 6.25rem !important; }

.pt-6,
.py-6 {
  padding-top: 6.25rem !important; }

.pr-6,
.px-6 {
  padding-right: 6.25rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 6.25rem !important; }

.pl-6,
.px-6 {
  padding-left: 6.25rem !important; }

.p-7 {
  padding: 12.5rem !important; }

.pt-7,
.py-7 {
  padding-top: 12.5rem !important; }

.pr-7,
.px-7 {
  padding-right: 12.5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 12.5rem !important; }

.pl-7,
.px-7 {
  padding-left: 12.5rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -6.25rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -6.25rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -6.25rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -6.25rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -6.25rem !important; }

.m-n7 {
  margin: -12.5rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -12.5rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -12.5rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -12.5rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -12.5rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 6.25rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 6.25rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 6.25rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6.25rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 6.25rem !important; }
  .m-sm-7 {
    margin: 12.5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 12.5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 12.5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 12.5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 12.5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 6.25rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 6.25rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 6.25rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6.25rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 6.25rem !important; }
  .p-sm-7 {
    padding: 12.5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 12.5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 12.5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 12.5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 12.5rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n6 {
    margin: -6.25rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -6.25rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -6.25rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -6.25rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -6.25rem !important; }
  .m-sm-n7 {
    margin: -12.5rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -12.5rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -12.5rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -12.5rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -12.5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 6.25rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 6.25rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 6.25rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6.25rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 6.25rem !important; }
  .m-md-7 {
    margin: 12.5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 12.5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 12.5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 12.5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 12.5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 6.25rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 6.25rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 6.25rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6.25rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 6.25rem !important; }
  .p-md-7 {
    padding: 12.5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 12.5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 12.5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 12.5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 12.5rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n6 {
    margin: -6.25rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -6.25rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -6.25rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -6.25rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -6.25rem !important; }
  .m-md-n7 {
    margin: -12.5rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -12.5rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -12.5rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -12.5rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -12.5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 6.25rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6.25rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 6.25rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6.25rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 6.25rem !important; }
  .m-lg-7 {
    margin: 12.5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 12.5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 12.5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 12.5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 12.5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 6.25rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 6.25rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 6.25rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6.25rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 6.25rem !important; }
  .p-lg-7 {
    padding: 12.5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 12.5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 12.5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 12.5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 12.5rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n6 {
    margin: -6.25rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -6.25rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -6.25rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -6.25rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -6.25rem !important; }
  .m-lg-n7 {
    margin: -12.5rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -12.5rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -12.5rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -12.5rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -12.5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 6.25rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 6.25rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 6.25rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 6.25rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 6.25rem !important; }
  .m-xl-7 {
    margin: 12.5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 12.5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 12.5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 12.5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 12.5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 6.25rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 6.25rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 6.25rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 6.25rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 6.25rem !important; }
  .p-xl-7 {
    padding: 12.5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 12.5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 12.5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 12.5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 12.5rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n6 {
    margin: -6.25rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -6.25rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -6.25rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -6.25rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -6.25rem !important; }
  .m-xl-n7 {
    margin: -12.5rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -12.5rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -12.5rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -12.5rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -12.5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 500 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary, h1 {
  color: #0e73cc !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #094b84 !important; }

.text-secondary {
  color: #696359 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #403c36 !important; }

.text-success {
  color: #0f9e7b !important; }

a.text-success:hover, a.text-success:focus {
  color: #085845 !important; }

.text-info {
  color: #00c2db !important; }

a.text-info:hover, a.text-info:focus {
  color: #007e8f !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #fc4848 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #f40404 !important; }

.text-light {
  color: #f4f3f1 !important; }

a.text-light:hover, a.text-light:focus {
  color: #d2cec6 !important; }

.text-dark {
  color: #23211e !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-body {
  color: #2a2d2d !important; }

.text-muted, .file-path.var-type,
.var-type,
.var-value.var-type {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* Color variants - https://codepen.io/migli/pen/QaGNVp */
/*  Add color variants to $colors*/
/* remap grays */
/* remap colors */
/* from Bootstrap 4 utilities - replaced $theme-colors with $colors */
/* text colors*/
.text-blue {
  color: #0e73cc !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #094b84 !important; }

.text-red {
  color: #fc4848 !important; }

a.text-red:hover, a.text-red:focus {
  color: #f40404 !important; }

.text-pink {
  color: #e91e63 !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #aa1145 !important; }

.text-yellow {
  color: #ffc107 !important; }

a.text-yellow:hover, a.text-yellow:focus {
  color: #ba8b00 !important; }

.text-gray, .text-light {
  color: #8c8476 !important; }

a.text-gray:hover, a.text-light:hover, a.text-gray:focus, a.text-light:focus {
  color: #635d53 !important; }

.text-gray-dark {
  color: #38352f !important; }

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #0e0e0c !important; }

.text-primary, h1 {
  color: #0e73cc !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #094b84 !important; }

.text-success {
  color: #0f9e7b !important; }

a.text-success:hover, a.text-success:focus {
  color: #085845 !important; }

.text-info {
  color: #00c2db !important; }

a.text-info:hover, a.text-info:focus {
  color: #007e8f !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #fc4848 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #f40404 !important; }

.text-blue-100 {
  color: #e7f1fa !important; }

a.text-blue-100:hover, a.text-blue-100:focus {
  color: #a8cced !important; }

.text-blue-200 {
  color: #c3dcf2 !important; }

a.text-blue-200:hover, a.text-blue-200:focus {
  color: #84b7e4 !important; }

.text-blue-300 {
  color: #87b9e6 !important; }

a.text-blue-300:hover, a.text-blue-300:focus {
  color: #4894d9 !important; }

.text-blue-400 {
  color: #4a96d9 !important; }

a.text-blue-400:hover, a.text-blue-400:focus {
  color: #2570b1 !important; }

.text-blue-500 {
  color: #0e73cc !important; }

a.text-blue-500:hover, a.text-blue-500:focus {
  color: #094b84 !important; }

.text-blue-600 {
  color: #0b5699 !important; }

a.text-blue-600:hover, a.text-blue-600:focus {
  color: #062e52 !important; }

.text-blue-700 {
  color: #073a66 !important; }

a.text-blue-700:hover, a.text-blue-700:focus {
  color: #02111e !important; }

.text-blue-800 {
  color: #041d33 !important; }

a.text-blue-800:hover, a.text-blue-800:focus {
  color: black !important; }

.text-blue-900 {
  color: #02111f !important; }

a.text-blue-900:hover, a.text-blue-900:focus {
  color: black !important; }

.text-red-100 {
  color: #ffeded !important; }

a.text-red-100:hover, a.text-red-100:focus {
  color: #ffa1a1 !important; }

.text-red-200 {
  color: #fed1d1 !important; }

a.text-red-200:hover, a.text-red-200:focus {
  color: #fc8686 !important; }

.text-red-300 {
  color: #fea4a4 !important; }

a.text-red-300:hover, a.text-red-300:focus {
  color: #fd5858 !important; }

.text-red-400 {
  color: #fd7676 !important; }

a.text-red-400:hover, a.text-red-400:focus {
  color: #fc2b2b !important; }

.text-red-500 {
  color: #fc4848 !important; }

a.text-red-500:hover, a.text-red-500:focus {
  color: #f40404 !important; }

.text-red-600 {
  color: #bd3636 !important; }

a.text-red-600:hover, a.text-red-600:focus {
  color: #822525 !important; }

.text-red-700 {
  color: #7e2424 !important; }

a.text-red-700:hover, a.text-red-700:focus {
  color: #431313 !important; }

.text-red-800 {
  color: #3f1212 !important; }

a.text-red-800:hover, a.text-red-800:focus {
  color: #040101 !important; }

.text-red-900 {
  color: #260b0b !important; }

a.text-red-900:hover, a.text-red-900:focus {
  color: black !important; }

.text-pink-100 {
  color: #fde9ef !important; }

a.text-pink-100:hover, a.text-pink-100:focus {
  color: #f7a3bc !important; }

.text-pink-200 {
  color: #fac7d8 !important; }

a.text-pink-200:hover, a.text-pink-200:focus {
  color: #f481a7 !important; }

.text-pink-300 {
  color: #f48fb1 !important; }

a.text-pink-300:hover, a.text-pink-300:focus {
  color: #ed4980 !important; }

.text-pink-400 {
  color: #ef568a !important; }

a.text-pink-400:hover, a.text-pink-400:focus {
  color: #e3155b !important; }

.text-pink-500 {
  color: #e91e63 !important; }

a.text-pink-500:hover, a.text-pink-500:focus {
  color: #aa1145 !important; }

.text-pink-600 {
  color: #af174a !important; }

a.text-pink-600:hover, a.text-pink-600:focus {
  color: #6b0e2d !important; }

.text-pink-700 {
  color: #750f32 !important; }

a.text-pink-700:hover, a.text-pink-700:focus {
  color: #310615 !important; }

.text-pink-800 {
  color: #3a0819 !important; }

a.text-pink-800:hover, a.text-pink-800:focus {
  color: black !important; }

.text-pink-900 {
  color: #23050f !important; }

a.text-pink-900:hover, a.text-pink-900:focus {
  color: black !important; }

.text-yellow-100 {
  color: #fff9e6 !important; }

a.text-yellow-100:hover, a.text-yellow-100:focus {
  color: #ffe79a !important; }

.text-yellow-200 {
  color: #fff0c1 !important; }

a.text-yellow-200:hover, a.text-yellow-200:focus {
  color: #ffdd75 !important; }

.text-yellow-300 {
  color: #ffe083 !important; }

a.text-yellow-300:hover, a.text-yellow-300:focus {
  color: #ffcd37 !important; }

.text-yellow-400 {
  color: #ffd145 !important; }

a.text-yellow-400:hover, a.text-yellow-400:focus {
  color: #f8ba00 !important; }

.text-yellow-500 {
  color: #ffc107 !important; }

a.text-yellow-500:hover, a.text-yellow-500:focus {
  color: #ba8b00 !important; }

.text-yellow-600 {
  color: #bf9105 !important; }

a.text-yellow-600:hover, a.text-yellow-600:focus {
  color: #745803 !important; }

.text-yellow-700 {
  color: #806104 !important; }

a.text-yellow-700:hover, a.text-yellow-700:focus {
  color: #362902 !important; }

.text-yellow-800 {
  color: #403002 !important; }

a.text-yellow-800:hover, a.text-yellow-800:focus {
  color: black !important; }

.text-yellow-900 {
  color: #261d01 !important; }

a.text-yellow-900:hover, a.text-yellow-900:focus {
  color: black !important; }

.text-gray-100 {
  color: #f4f3f1 !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #d2cec6 !important; }

.text-gray-200 {
  color: #e2e0dd !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #bfbab4 !important; }

.text-gray-300 {
  color: #c6c2bb !important; }

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #a39d91 !important; }

.text-gray-400 {
  color: #a9a398 !important; }

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #857d6f !important; }

.text-gray-500 {
  color: #8c8476 !important; }

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #635d53 !important; }

.text-gray-600 {
  color: #696359 !important; }

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #403c36 !important; }

.text-gray-700 {
  color: #46423b !important; }

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #1c1b18 !important; }

.text-gray-800 {
  color: #23211e !important; }

a.text-gray-800:hover, a.text-gray-800:focus {
  color: black !important; }

.text-gray-900 {
  color: #151412 !important; }

a.text-gray-900:hover, a.text-gray-900:focus {
  color: black !important; }

.text-gray-dark-100 {
  color: #ebebea !important; }

a.text-gray-dark-100:hover, a.text-gray-dark-100:focus {
  color: #c6c6c3 !important; }

.text-gray-dark-200 {
  color: #cdcdcb !important; }

a.text-gray-dark-200:hover, a.text-gray-dark-200:focus {
  color: #a8a8a4 !important; }

.text-gray-dark-300 {
  color: #9c9a97 !important; }

a.text-gray-dark-300:hover, a.text-gray-dark-300:focus {
  color: #767470 !important; }

.text-gray-dark-400 {
  color: #6a6863 !important; }

a.text-gray-dark-400:hover, a.text-gray-dark-400:focus {
  color: #42413e !important; }

.text-gray-dark-500 {
  color: #38352f !important; }

a.text-gray-dark-500:hover, a.text-gray-dark-500:focus {
  color: #0e0e0c !important; }

.text-gray-dark-600 {
  color: #2a2823 !important; }

a.text-gray-dark-600:hover, a.text-gray-dark-600:focus {
  color: black !important; }

.text-gray-dark-700 {
  color: #1c1b18 !important; }

a.text-gray-dark-700:hover, a.text-gray-dark-700:focus {
  color: black !important; }

.text-gray-dark-800 {
  color: #0e0d0c !important; }

a.text-gray-dark-800:hover, a.text-gray-dark-800:focus {
  color: black !important; }

.text-gray-dark-900 {
  color: #080807 !important; }

a.text-gray-dark-900:hover, a.text-gray-dark-900:focus {
  color: black !important; }

.text-primary-100 {
  color: #e7f1fa !important; }

a.text-primary-100:hover, a.text-primary-100:focus {
  color: #a8cced !important; }

.text-primary-200 {
  color: #c3dcf2 !important; }

a.text-primary-200:hover, a.text-primary-200:focus {
  color: #84b7e4 !important; }

.text-primary-300 {
  color: #87b9e6 !important; }

a.text-primary-300:hover, a.text-primary-300:focus {
  color: #4894d9 !important; }

.text-primary-400 {
  color: #4a96d9 !important; }

a.text-primary-400:hover, a.text-primary-400:focus {
  color: #2570b1 !important; }

.text-primary-500 {
  color: #0e73cc !important; }

a.text-primary-500:hover, a.text-primary-500:focus {
  color: #094b84 !important; }

.text-primary-600 {
  color: #0b5699 !important; }

a.text-primary-600:hover, a.text-primary-600:focus {
  color: #062e52 !important; }

.text-primary-700 {
  color: #073a66 !important; }

a.text-primary-700:hover, a.text-primary-700:focus {
  color: #02111e !important; }

.text-primary-800 {
  color: #041d33 !important; }

a.text-primary-800:hover, a.text-primary-800:focus {
  color: black !important; }

.text-primary-900 {
  color: #02111f !important; }

a.text-primary-900:hover, a.text-primary-900:focus {
  color: black !important; }

.text-success-100 {
  color: #e7f5f2 !important; }

a.text-success-100:hover, a.text-success-100:focus {
  color: #b1dfd5 !important; }

.text-success-200 {
  color: #c3e7de !important; }

a.text-success-200:hover, a.text-success-200:focus {
  color: #8cd1c0 !important; }

.text-success-300 {
  color: #87cfbd !important; }

a.text-success-300:hover, a.text-success-300:focus {
  color: #50b99f !important; }

.text-success-400 {
  color: #4bb69c !important; }

a.text-success-400:hover, a.text-success-400:focus {
  color: #34806e !important; }

.text-success-500 {
  color: #0f9e7b !important; }

a.text-success-500:hover, a.text-success-500:focus {
  color: #085845 !important; }

.text-success-600 {
  color: #0b775c !important; }

a.text-success-600:hover, a.text-success-600:focus {
  color: #053126 !important; }

.text-success-700 {
  color: #084f3e !important; }

a.text-success-700:hover, a.text-success-700:focus {
  color: #010a07 !important; }

.text-success-800 {
  color: #04281f !important; }

a.text-success-800:hover, a.text-success-800:focus {
  color: black !important; }

.text-success-900 {
  color: #021812 !important; }

a.text-success-900:hover, a.text-success-900:focus {
  color: black !important; }

.text-info-100 {
  color: #e6f9fb !important; }

a.text-info-100:hover, a.text-info-100:focus {
  color: #a4e9f0 !important; }

.text-info-200 {
  color: #bff0f6 !important; }

a.text-info-200:hover, a.text-info-200:focus {
  color: #7ce0ed !important; }

.text-info-300 {
  color: #80e1ed !important; }

a.text-info-300:hover, a.text-info-300:focus {
  color: #3dd1e4 !important; }

.text-info-400 {
  color: #40d1e4 !important; }

a.text-info-400:hover, a.text-info-400:focus {
  color: #1baabd !important; }

.text-info-500 {
  color: #00c2db !important; }

a.text-info-500:hover, a.text-info-500:focus {
  color: #007e8f !important; }

.text-info-600 {
  color: #0092a4 !important; }

a.text-info-600:hover, a.text-info-600:focus {
  color: #004e58 !important; }

.text-info-700 {
  color: #00616e !important; }

a.text-info-700:hover, a.text-info-700:focus {
  color: #001e22 !important; }

.text-info-800 {
  color: #003137 !important; }

a.text-info-800:hover, a.text-info-800:focus {
  color: black !important; }

.text-info-900 {
  color: #001d21 !important; }

a.text-info-900:hover, a.text-info-900:focus {
  color: black !important; }

.text-warning-100 {
  color: #fff9e6 !important; }

a.text-warning-100:hover, a.text-warning-100:focus {
  color: #ffe79a !important; }

.text-warning-200 {
  color: #fff0c1 !important; }

a.text-warning-200:hover, a.text-warning-200:focus {
  color: #ffdd75 !important; }

.text-warning-300 {
  color: #ffe083 !important; }

a.text-warning-300:hover, a.text-warning-300:focus {
  color: #ffcd37 !important; }

.text-warning-400 {
  color: #ffd145 !important; }

a.text-warning-400:hover, a.text-warning-400:focus {
  color: #f8ba00 !important; }

.text-warning-500 {
  color: #ffc107 !important; }

a.text-warning-500:hover, a.text-warning-500:focus {
  color: #ba8b00 !important; }

.text-warning-600 {
  color: #bf9105 !important; }

a.text-warning-600:hover, a.text-warning-600:focus {
  color: #745803 !important; }

.text-warning-700 {
  color: #806104 !important; }

a.text-warning-700:hover, a.text-warning-700:focus {
  color: #362902 !important; }

.text-warning-800 {
  color: #403002 !important; }

a.text-warning-800:hover, a.text-warning-800:focus {
  color: black !important; }

.text-warning-900 {
  color: #261d01 !important; }

a.text-warning-900:hover, a.text-warning-900:focus {
  color: black !important; }

.text-danger-100 {
  color: #ffeded !important; }

a.text-danger-100:hover, a.text-danger-100:focus {
  color: #ffa1a1 !important; }

.text-danger-200 {
  color: #fed1d1 !important; }

a.text-danger-200:hover, a.text-danger-200:focus {
  color: #fc8686 !important; }

.text-danger-300 {
  color: #fea4a4 !important; }

a.text-danger-300:hover, a.text-danger-300:focus {
  color: #fd5858 !important; }

.text-danger-400 {
  color: #fd7676 !important; }

a.text-danger-400:hover, a.text-danger-400:focus {
  color: #fc2b2b !important; }

.text-danger-500 {
  color: #fc4848 !important; }

a.text-danger-500:hover, a.text-danger-500:focus {
  color: #f40404 !important; }

.text-danger-600 {
  color: #bd3636 !important; }

a.text-danger-600:hover, a.text-danger-600:focus {
  color: #822525 !important; }

.text-danger-700 {
  color: #7e2424 !important; }

a.text-danger-700:hover, a.text-danger-700:focus {
  color: #431313 !important; }

.text-danger-800 {
  color: #3f1212 !important; }

a.text-danger-800:hover, a.text-danger-800:focus {
  color: #040101 !important; }

.text-danger-900 {
  color: #260b0b !important; }

a.text-danger-900:hover, a.text-danger-900:focus {
  color: black !important; }

/* background colors*/
.bg-blue {
  background-color: #0e73cc !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #0b589c !important; }

.bg-red {
  background-color: #fc4848 !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #fb1616 !important; }

.bg-pink {
  background-color: #e91e63 !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #c1134e !important; }

.bg-yellow {
  background-color: #ffc107 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #d39e00 !important; }

.bg-gray, .numbered > dt:before,
.numbered > li:before {
  background-color: #8c8476 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #716a5e !important; }

.bg-gray-dark {
  background-color: #38352f !important; }

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #1c1b18 !important; }

.bg-primary {
  background-color: #0e73cc !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0b589c !important; }

.bg-success {
  background-color: #0f9e7b !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0b6f57 !important; }

.bg-info {
  background-color: #00c2db !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0095a8 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #fc4848 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #fb1616 !important; }

.bg-blue-100 {
  background-color: #e7f1fa !important; }

a.bg-blue-100:hover, a.bg-blue-100:focus,
button.bg-blue-100:hover,
button.bg-blue-100:focus {
  background-color: #bdd8f1 !important; }

.bg-blue-200 {
  background-color: #c3dcf2 !important; }

a.bg-blue-200:hover, a.bg-blue-200:focus,
button.bg-blue-200:hover,
button.bg-blue-200:focus {
  background-color: #99c4e9 !important; }

.bg-blue-300 {
  background-color: #87b9e6 !important; }

a.bg-blue-300:hover, a.bg-blue-300:focus,
button.bg-blue-300:hover,
button.bg-blue-300:focus {
  background-color: #5da0dd !important; }

.bg-blue-400 {
  background-color: #4a96d9 !important; }

a.bg-blue-400:hover, a.bg-blue-400:focus,
button.bg-blue-400:hover,
button.bg-blue-400:focus {
  background-color: #2a7dc6 !important; }

.bg-blue-500 {
  background-color: #0e73cc !important; }

a.bg-blue-500:hover, a.bg-blue-500:focus,
button.bg-blue-500:hover,
button.bg-blue-500:focus {
  background-color: #0b589c !important; }

.bg-blue-600 {
  background-color: #0b5699 !important; }

a.bg-blue-600:hover, a.bg-blue-600:focus,
button.bg-blue-600:hover,
button.bg-blue-600:focus {
  background-color: #083b69 !important; }

.bg-blue-700 {
  background-color: #073a66 !important; }

a.bg-blue-700:hover, a.bg-blue-700:focus,
button.bg-blue-700:hover,
button.bg-blue-700:focus {
  background-color: #041f36 !important; }

.bg-blue-800 {
  background-color: #041d33 !important; }

a.bg-blue-800:hover, a.bg-blue-800:focus,
button.bg-blue-800:hover,
button.bg-blue-800:focus {
  background-color: #000204 !important; }

.bg-blue-900 {
  background-color: #02111f !important; }

a.bg-blue-900:hover, a.bg-blue-900:focus,
button.bg-blue-900:hover,
button.bg-blue-900:focus {
  background-color: black !important; }

.bg-red-100 {
  background-color: #ffeded !important; }

a.bg-red-100:hover, a.bg-red-100:focus,
button.bg-red-100:hover,
button.bg-red-100:focus {
  background-color: #ffbaba !important; }

.bg-red-200 {
  background-color: #fed1d1 !important; }

a.bg-red-200:hover, a.bg-red-200:focus,
button.bg-red-200:hover,
button.bg-red-200:focus {
  background-color: #fd9f9f !important; }

.bg-red-300 {
  background-color: #fea4a4 !important; }

a.bg-red-300:hover, a.bg-red-300:focus,
button.bg-red-300:hover,
button.bg-red-300:focus {
  background-color: #fd7272 !important; }

.bg-red-400 {
  background-color: #fd7676 !important; }

a.bg-red-400:hover, a.bg-red-400:focus,
button.bg-red-400:hover,
button.bg-red-400:focus {
  background-color: #fc4444 !important; }

.bg-red-500 {
  background-color: #fc4848 !important; }

a.bg-red-500:hover, a.bg-red-500:focus,
button.bg-red-500:hover,
button.bg-red-500:focus {
  background-color: #fb1616 !important; }

.bg-red-600 {
  background-color: #bd3636 !important; }

a.bg-red-600:hover, a.bg-red-600:focus,
button.bg-red-600:hover,
button.bg-red-600:focus {
  background-color: #952b2b !important; }

.bg-red-700 {
  background-color: #7e2424 !important; }

a.bg-red-700:hover, a.bg-red-700:focus,
button.bg-red-700:hover,
button.bg-red-700:focus {
  background-color: #561919 !important; }

.bg-red-800 {
  background-color: #3f1212 !important; }

a.bg-red-800:hover, a.bg-red-800:focus,
button.bg-red-800:hover,
button.bg-red-800:focus {
  background-color: #170707 !important; }

.bg-red-900 {
  background-color: #260b0b !important; }

a.bg-red-900:hover, a.bg-red-900:focus,
button.bg-red-900:hover,
button.bg-red-900:focus {
  background-color: black !important; }

.bg-pink-100 {
  background-color: #fde9ef !important; }

a.bg-pink-100:hover, a.bg-pink-100:focus,
button.bg-pink-100:hover,
button.bg-pink-100:focus {
  background-color: #f9bacd !important; }

.bg-pink-200 {
  background-color: #fac7d8 !important; }

a.bg-pink-200:hover, a.bg-pink-200:focus,
button.bg-pink-200:hover,
button.bg-pink-200:focus {
  background-color: #f698b7 !important; }

.bg-pink-300 {
  background-color: #f48fb1 !important; }

a.bg-pink-300:hover, a.bg-pink-300:focus,
button.bg-pink-300:hover,
button.bg-pink-300:focus {
  background-color: #ef6191 !important; }

.bg-pink-400 {
  background-color: #ef568a !important; }

a.bg-pink-400:hover, a.bg-pink-400:focus,
button.bg-pink-400:hover,
button.bg-pink-400:focus {
  background-color: #eb276a !important; }

.bg-pink-500 {
  background-color: #e91e63 !important; }

a.bg-pink-500:hover, a.bg-pink-500:focus,
button.bg-pink-500:hover,
button.bg-pink-500:focus {
  background-color: #c1134e !important; }

.bg-pink-600 {
  background-color: #af174a !important; }

a.bg-pink-600:hover, a.bg-pink-600:focus,
button.bg-pink-600:hover,
button.bg-pink-600:focus {
  background-color: #821137 !important; }

.bg-pink-700 {
  background-color: #750f32 !important; }

a.bg-pink-700:hover, a.bg-pink-700:focus,
button.bg-pink-700:hover,
button.bg-pink-700:focus {
  background-color: #48091f !important; }

.bg-pink-800 {
  background-color: #3a0819 !important; }

a.bg-pink-800:hover, a.bg-pink-800:focus,
button.bg-pink-800:hover,
button.bg-pink-800:focus {
  background-color: #0d0206 !important; }

.bg-pink-900 {
  background-color: #23050f !important; }

a.bg-pink-900:hover, a.bg-pink-900:focus,
button.bg-pink-900:hover,
button.bg-pink-900:focus {
  background-color: black !important; }

.bg-yellow-100 {
  background-color: #fff9e6 !important; }

a.bg-yellow-100:hover, a.bg-yellow-100:focus,
button.bg-yellow-100:hover,
button.bg-yellow-100:focus {
  background-color: #ffedb3 !important; }

.bg-yellow-200 {
  background-color: #fff0c1 !important; }

a.bg-yellow-200:hover, a.bg-yellow-200:focus,
button.bg-yellow-200:hover,
button.bg-yellow-200:focus {
  background-color: #ffe48e !important; }

.bg-yellow-300 {
  background-color: #ffe083 !important; }

a.bg-yellow-300:hover, a.bg-yellow-300:focus,
button.bg-yellow-300:hover,
button.bg-yellow-300:focus {
  background-color: #ffd350 !important; }

.bg-yellow-400 {
  background-color: #ffd145 !important; }

a.bg-yellow-400:hover, a.bg-yellow-400:focus,
button.bg-yellow-400:hover,
button.bg-yellow-400:focus {
  background-color: #ffc412 !important; }

.bg-yellow-500 {
  background-color: #ffc107 !important; }

a.bg-yellow-500:hover, a.bg-yellow-500:focus,
button.bg-yellow-500:hover,
button.bg-yellow-500:focus {
  background-color: #d39e00 !important; }

.bg-yellow-600 {
  background-color: #bf9105 !important; }

a.bg-yellow-600:hover, a.bg-yellow-600:focus,
button.bg-yellow-600:hover,
button.bg-yellow-600:focus {
  background-color: #8d6b04 !important; }

.bg-yellow-700 {
  background-color: #806104 !important; }

a.bg-yellow-700:hover, a.bg-yellow-700:focus,
button.bg-yellow-700:hover,
button.bg-yellow-700:focus {
  background-color: #4f3c02 !important; }

.bg-yellow-800 {
  background-color: #403002 !important; }

a.bg-yellow-800:hover, a.bg-yellow-800:focus,
button.bg-yellow-800:hover,
button.bg-yellow-800:focus {
  background-color: #0f0b00 !important; }

.bg-yellow-900 {
  background-color: #261d01 !important; }

a.bg-yellow-900:hover, a.bg-yellow-900:focus,
button.bg-yellow-900:hover,
button.bg-yellow-900:focus {
  background-color: black !important; }

.bg-gray-100, .badge-light {
  background-color: #f4f3f1 !important; }

a.bg-gray-100:hover, a.badge-light:hover, a.bg-gray-100:focus, a.badge-light:focus,
button.bg-gray-100:hover,
button.badge-light:hover,
button.bg-gray-100:focus,
button.badge-light:focus {
  background-color: #dedbd4 !important; }

.bg-gray-200 {
  background-color: #e2e0dd !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cbc7c1 !important; }

.bg-gray-300 {
  background-color: #c6c2bb !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #afa99f !important; }

.bg-gray-400 {
  background-color: #a9a398 !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #928a7c !important; }

.bg-gray-500 {
  background-color: #8c8476 !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #716a5e !important; }

.bg-gray-600 {
  background-color: #696359 !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #4d4942 !important; }

.bg-gray-700 {
  background-color: #46423b !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #2a2824 !important; }

.bg-gray-800 {
  background-color: #23211e !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #080706 !important; }

.bg-gray-900 {
  background-color: #151412 !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: black !important; }

.bg-gray-dark-100 {
  background-color: #ebebea !important; }

a.bg-gray-dark-100:hover, a.bg-gray-dark-100:focus,
button.bg-gray-dark-100:hover,
button.bg-gray-dark-100:focus {
  background-color: #d2d2d0 !important; }

.bg-gray-dark-200 {
  background-color: #cdcdcb !important; }

a.bg-gray-dark-200:hover, a.bg-gray-dark-200:focus,
button.bg-gray-dark-200:hover,
button.bg-gray-dark-200:focus {
  background-color: #b4b4b1 !important; }

.bg-gray-dark-300 {
  background-color: #9c9a97 !important; }

a.bg-gray-dark-300:hover, a.bg-gray-dark-300:focus,
button.bg-gray-dark-300:hover,
button.bg-gray-dark-300:focus {
  background-color: #83817d !important; }

.bg-gray-dark-400 {
  background-color: #6a6863 !important; }

a.bg-gray-dark-400:hover, a.bg-gray-dark-400:focus,
button.bg-gray-dark-400:hover,
button.bg-gray-dark-400:focus {
  background-color: #504e4a !important; }

.bg-gray-dark-500 {
  background-color: #38352f !important; }

a.bg-gray-dark-500:hover, a.bg-gray-dark-500:focus,
button.bg-gray-dark-500:hover,
button.bg-gray-dark-500:focus {
  background-color: #1c1b18 !important; }

.bg-gray-dark-600 {
  background-color: #2a2823 !important; }

a.bg-gray-dark-600:hover, a.bg-gray-dark-600:focus,
button.bg-gray-dark-600:hover,
button.bg-gray-dark-600:focus {
  background-color: #0e0e0c !important; }

.bg-gray-dark-700 {
  background-color: #1c1b18 !important; }

a.bg-gray-dark-700:hover, a.bg-gray-dark-700:focus,
button.bg-gray-dark-700:hover,
button.bg-gray-dark-700:focus {
  background-color: #010100 !important; }

.bg-gray-dark-800 {
  background-color: #0e0d0c !important; }

a.bg-gray-dark-800:hover, a.bg-gray-dark-800:focus,
button.bg-gray-dark-800:hover,
button.bg-gray-dark-800:focus {
  background-color: black !important; }

.bg-gray-dark-900 {
  background-color: #080807 !important; }

a.bg-gray-dark-900:hover, a.bg-gray-dark-900:focus,
button.bg-gray-dark-900:hover,
button.bg-gray-dark-900:focus {
  background-color: black !important; }

.bg-primary-100 {
  background-color: #e7f1fa !important; }

a.bg-primary-100:hover, a.bg-primary-100:focus,
button.bg-primary-100:hover,
button.bg-primary-100:focus {
  background-color: #bdd8f1 !important; }

.bg-primary-200 {
  background-color: #c3dcf2 !important; }

a.bg-primary-200:hover, a.bg-primary-200:focus,
button.bg-primary-200:hover,
button.bg-primary-200:focus {
  background-color: #99c4e9 !important; }

.bg-primary-300 {
  background-color: #87b9e6 !important; }

a.bg-primary-300:hover, a.bg-primary-300:focus,
button.bg-primary-300:hover,
button.bg-primary-300:focus {
  background-color: #5da0dd !important; }

.bg-primary-400 {
  background-color: #4a96d9 !important; }

a.bg-primary-400:hover, a.bg-primary-400:focus,
button.bg-primary-400:hover,
button.bg-primary-400:focus {
  background-color: #2a7dc6 !important; }

.bg-primary-500 {
  background-color: #0e73cc !important; }

a.bg-primary-500:hover, a.bg-primary-500:focus,
button.bg-primary-500:hover,
button.bg-primary-500:focus {
  background-color: #0b589c !important; }

.bg-primary-600 {
  background-color: #0b5699 !important; }

a.bg-primary-600:hover, a.bg-primary-600:focus,
button.bg-primary-600:hover,
button.bg-primary-600:focus {
  background-color: #083b69 !important; }

.bg-primary-700 {
  background-color: #073a66 !important; }

a.bg-primary-700:hover, a.bg-primary-700:focus,
button.bg-primary-700:hover,
button.bg-primary-700:focus {
  background-color: #041f36 !important; }

.bg-primary-800 {
  background-color: #041d33 !important; }

a.bg-primary-800:hover, a.bg-primary-800:focus,
button.bg-primary-800:hover,
button.bg-primary-800:focus {
  background-color: #000204 !important; }

.bg-primary-900 {
  background-color: #02111f !important; }

a.bg-primary-900:hover, a.bg-primary-900:focus,
button.bg-primary-900:hover,
button.bg-primary-900:focus {
  background-color: black !important; }

.bg-success-100 {
  background-color: #e7f5f2 !important; }

a.bg-success-100:hover, a.bg-success-100:focus,
button.bg-success-100:hover,
button.bg-success-100:focus {
  background-color: #c3e6df !important; }

.bg-success-200 {
  background-color: #c3e7de !important; }

a.bg-success-200:hover, a.bg-success-200:focus,
button.bg-success-200:hover,
button.bg-success-200:focus {
  background-color: #9fd8ca !important; }

.bg-success-300 {
  background-color: #87cfbd !important; }

a.bg-success-300:hover, a.bg-success-300:focus,
button.bg-success-300:hover,
button.bg-success-300:focus {
  background-color: #63c0a9 !important; }

.bg-success-400 {
  background-color: #4bb69c !important; }

a.bg-success-400:hover, a.bg-success-400:focus,
button.bg-success-400:hover,
button.bg-success-400:focus {
  background-color: #3b937d !important; }

.bg-success-500 {
  background-color: #0f9e7b !important; }

a.bg-success-500:hover, a.bg-success-500:focus,
button.bg-success-500:hover,
button.bg-success-500:focus {
  background-color: #0b6f57 !important; }

.bg-success-600 {
  background-color: #0b775c !important; }

a.bg-success-600:hover, a.bg-success-600:focus,
button.bg-success-600:hover,
button.bg-success-600:focus {
  background-color: #074838 !important; }

.bg-success-700 {
  background-color: #084f3e !important; }

a.bg-success-700:hover, a.bg-success-700:focus,
button.bg-success-700:hover,
button.bg-success-700:focus {
  background-color: #03211a !important; }

.bg-success-800 {
  background-color: #04281f !important; }

a.bg-success-800:hover, a.bg-success-800:focus,
button.bg-success-800:hover,
button.bg-success-800:focus {
  background-color: black !important; }

.bg-success-900 {
  background-color: #021812 !important; }

a.bg-success-900:hover, a.bg-success-900:focus,
button.bg-success-900:hover,
button.bg-success-900:focus {
  background-color: black !important; }

.bg-info-100 {
  background-color: #e6f9fb !important; }

a.bg-info-100:hover, a.bg-info-100:focus,
button.bg-info-100:hover,
button.bg-info-100:focus {
  background-color: #baeef4 !important; }

.bg-info-200 {
  background-color: #bff0f6 !important; }

a.bg-info-200:hover, a.bg-info-200:focus,
button.bg-info-200:hover,
button.bg-info-200:focus {
  background-color: #92e6f0 !important; }

.bg-info-300 {
  background-color: #80e1ed !important; }

a.bg-info-300:hover, a.bg-info-300:focus,
button.bg-info-300:hover,
button.bg-info-300:focus {
  background-color: #53d6e7 !important; }

.bg-info-400 {
  background-color: #40d1e4 !important; }

a.bg-info-400:hover, a.bg-info-400:focus,
button.bg-info-400:hover,
button.bg-info-400:focus {
  background-color: #1ebed3 !important; }

.bg-info-500 {
  background-color: #00c2db !important; }

a.bg-info-500:hover, a.bg-info-500:focus,
button.bg-info-500:hover,
button.bg-info-500:focus {
  background-color: #0095a8 !important; }

.bg-info-600 {
  background-color: #0092a4 !important; }

a.bg-info-600:hover, a.bg-info-600:focus,
button.bg-info-600:hover,
button.bg-info-600:focus {
  background-color: #006571 !important; }

.bg-info-700 {
  background-color: #00616e !important; }

a.bg-info-700:hover, a.bg-info-700:focus,
button.bg-info-700:hover,
button.bg-info-700:focus {
  background-color: #00343b !important; }

.bg-info-800 {
  background-color: #003137 !important; }

a.bg-info-800:hover, a.bg-info-800:focus,
button.bg-info-800:hover,
button.bg-info-800:focus {
  background-color: #000404 !important; }

.bg-info-900 {
  background-color: #001d21 !important; }

a.bg-info-900:hover, a.bg-info-900:focus,
button.bg-info-900:hover,
button.bg-info-900:focus {
  background-color: black !important; }

.bg-warning-100 {
  background-color: #fff9e6 !important; }

a.bg-warning-100:hover, a.bg-warning-100:focus,
button.bg-warning-100:hover,
button.bg-warning-100:focus {
  background-color: #ffedb3 !important; }

.bg-warning-200 {
  background-color: #fff0c1 !important; }

a.bg-warning-200:hover, a.bg-warning-200:focus,
button.bg-warning-200:hover,
button.bg-warning-200:focus {
  background-color: #ffe48e !important; }

.bg-warning-300 {
  background-color: #ffe083 !important; }

a.bg-warning-300:hover, a.bg-warning-300:focus,
button.bg-warning-300:hover,
button.bg-warning-300:focus {
  background-color: #ffd350 !important; }

.bg-warning-400 {
  background-color: #ffd145 !important; }

a.bg-warning-400:hover, a.bg-warning-400:focus,
button.bg-warning-400:hover,
button.bg-warning-400:focus {
  background-color: #ffc412 !important; }

.bg-warning-500 {
  background-color: #ffc107 !important; }

a.bg-warning-500:hover, a.bg-warning-500:focus,
button.bg-warning-500:hover,
button.bg-warning-500:focus {
  background-color: #d39e00 !important; }

.bg-warning-600 {
  background-color: #bf9105 !important; }

a.bg-warning-600:hover, a.bg-warning-600:focus,
button.bg-warning-600:hover,
button.bg-warning-600:focus {
  background-color: #8d6b04 !important; }

.bg-warning-700 {
  background-color: #806104 !important; }

a.bg-warning-700:hover, a.bg-warning-700:focus,
button.bg-warning-700:hover,
button.bg-warning-700:focus {
  background-color: #4f3c02 !important; }

.bg-warning-800 {
  background-color: #403002 !important; }

a.bg-warning-800:hover, a.bg-warning-800:focus,
button.bg-warning-800:hover,
button.bg-warning-800:focus {
  background-color: #0f0b00 !important; }

.bg-warning-900 {
  background-color: #261d01 !important; }

a.bg-warning-900:hover, a.bg-warning-900:focus,
button.bg-warning-900:hover,
button.bg-warning-900:focus {
  background-color: black !important; }

.bg-danger-100 {
  background-color: #ffeded !important; }

a.bg-danger-100:hover, a.bg-danger-100:focus,
button.bg-danger-100:hover,
button.bg-danger-100:focus {
  background-color: #ffbaba !important; }

.bg-danger-200 {
  background-color: #fed1d1 !important; }

a.bg-danger-200:hover, a.bg-danger-200:focus,
button.bg-danger-200:hover,
button.bg-danger-200:focus {
  background-color: #fd9f9f !important; }

.bg-danger-300 {
  background-color: #fea4a4 !important; }

a.bg-danger-300:hover, a.bg-danger-300:focus,
button.bg-danger-300:hover,
button.bg-danger-300:focus {
  background-color: #fd7272 !important; }

.bg-danger-400 {
  background-color: #fd7676 !important; }

a.bg-danger-400:hover, a.bg-danger-400:focus,
button.bg-danger-400:hover,
button.bg-danger-400:focus {
  background-color: #fc4444 !important; }

.bg-danger-500 {
  background-color: #fc4848 !important; }

a.bg-danger-500:hover, a.bg-danger-500:focus,
button.bg-danger-500:hover,
button.bg-danger-500:focus {
  background-color: #fb1616 !important; }

.bg-danger-600 {
  background-color: #bd3636 !important; }

a.bg-danger-600:hover, a.bg-danger-600:focus,
button.bg-danger-600:hover,
button.bg-danger-600:focus {
  background-color: #952b2b !important; }

.bg-danger-700 {
  background-color: #7e2424 !important; }

a.bg-danger-700:hover, a.bg-danger-700:focus,
button.bg-danger-700:hover,
button.bg-danger-700:focus {
  background-color: #561919 !important; }

.bg-danger-800 {
  background-color: #3f1212 !important; }

a.bg-danger-800:hover, a.bg-danger-800:focus,
button.bg-danger-800:hover,
button.bg-danger-800:focus {
  background-color: #170707 !important; }

.bg-danger-900 {
  background-color: #260b0b !important; }

a.bg-danger-900:hover, a.bg-danger-900:focus,
button.bg-danger-900:hover,
button.bg-danger-900:focus {
  background-color: black !important; }

/* background text contrast*/
.bg-blue {
  color: #fff; }

.bg-red {
  color: #fff; }

.bg-pink {
  color: #fff; }

.bg-yellow {
  color: #212529; }

.bg-gray, .numbered > dt:before,
.numbered > li:before {
  color: #fff; }

.bg-gray-dark {
  color: #fff; }

.bg-primary {
  color: #fff; }

.bg-success {
  color: #fff; }

.bg-info {
  color: #fff; }

.bg-warning {
  color: #212529; }

.bg-danger {
  color: #fff; }

.bg-blue-100 {
  color: #212529; }

.bg-blue-200 {
  color: #212529; }

.bg-blue-300 {
  color: #212529; }

.bg-blue-400 {
  color: #fff; }

.bg-blue-500 {
  color: #fff; }

.bg-blue-600 {
  color: #fff; }

.bg-blue-700 {
  color: #fff; }

.bg-blue-800 {
  color: #fff; }

.bg-blue-900 {
  color: #fff; }

.bg-red-100 {
  color: #212529; }

.bg-red-200 {
  color: #212529; }

.bg-red-300 {
  color: #212529; }

.bg-red-400 {
  color: #212529; }

.bg-red-500 {
  color: #fff; }

.bg-red-600 {
  color: #fff; }

.bg-red-700 {
  color: #fff; }

.bg-red-800 {
  color: #fff; }

.bg-red-900 {
  color: #fff; }

.bg-pink-100 {
  color: #212529; }

.bg-pink-200 {
  color: #212529; }

.bg-pink-300 {
  color: #212529; }

.bg-pink-400 {
  color: #fff; }

.bg-pink-500 {
  color: #fff; }

.bg-pink-600 {
  color: #fff; }

.bg-pink-700 {
  color: #fff; }

.bg-pink-800 {
  color: #fff; }

.bg-pink-900 {
  color: #fff; }

.bg-yellow-100 {
  color: #212529; }

.bg-yellow-200 {
  color: #212529; }

.bg-yellow-300 {
  color: #212529; }

.bg-yellow-400 {
  color: #212529; }

.bg-yellow-500 {
  color: #212529; }

.bg-yellow-600 {
  color: #fff; }

.bg-yellow-700 {
  color: #fff; }

.bg-yellow-800 {
  color: #fff; }

.bg-yellow-900 {
  color: #fff; }

.bg-gray-100, .badge-light {
  color: #212529; }

.bg-gray-200 {
  color: #212529; }

.bg-gray-300 {
  color: #212529; }

.bg-gray-400 {
  color: #212529; }

.bg-gray-500 {
  color: #fff; }

.bg-gray-600 {
  color: #fff; }

.bg-gray-700 {
  color: #fff; }

.bg-gray-800 {
  color: #fff; }

.bg-gray-900 {
  color: #fff; }

.bg-gray-dark-100 {
  color: #212529; }

.bg-gray-dark-200 {
  color: #212529; }

.bg-gray-dark-300 {
  color: #212529; }

.bg-gray-dark-400 {
  color: #fff; }

.bg-gray-dark-500 {
  color: #fff; }

.bg-gray-dark-600 {
  color: #fff; }

.bg-gray-dark-700 {
  color: #fff; }

.bg-gray-dark-800 {
  color: #fff; }

.bg-gray-dark-900 {
  color: #fff; }

.bg-primary-100 {
  color: #212529; }

.bg-primary-200 {
  color: #212529; }

.bg-primary-300 {
  color: #212529; }

.bg-primary-400 {
  color: #fff; }

.bg-primary-500 {
  color: #fff; }

.bg-primary-600 {
  color: #fff; }

.bg-primary-700 {
  color: #fff; }

.bg-primary-800 {
  color: #fff; }

.bg-primary-900 {
  color: #fff; }

.bg-success-100 {
  color: #212529; }

.bg-success-200 {
  color: #212529; }

.bg-success-300 {
  color: #212529; }

.bg-success-400 {
  color: #fff; }

.bg-success-500 {
  color: #fff; }

.bg-success-600 {
  color: #fff; }

.bg-success-700 {
  color: #fff; }

.bg-success-800 {
  color: #fff; }

.bg-success-900 {
  color: #fff; }

.bg-info-100 {
  color: #212529; }

.bg-info-200 {
  color: #212529; }

.bg-info-300 {
  color: #212529; }

.bg-info-400 {
  color: #212529; }

.bg-info-500 {
  color: #fff; }

.bg-info-600 {
  color: #fff; }

.bg-info-700 {
  color: #fff; }

.bg-info-800 {
  color: #fff; }

.bg-info-900 {
  color: #fff; }

.bg-warning-100 {
  color: #212529; }

.bg-warning-200 {
  color: #212529; }

.bg-warning-300 {
  color: #212529; }

.bg-warning-400 {
  color: #212529; }

.bg-warning-500 {
  color: #212529; }

.bg-warning-600 {
  color: #fff; }

.bg-warning-700 {
  color: #fff; }

.bg-warning-800 {
  color: #fff; }

.bg-warning-900 {
  color: #fff; }

.bg-danger-100 {
  color: #212529; }

.bg-danger-200 {
  color: #212529; }

.bg-danger-300 {
  color: #212529; }

.bg-danger-400 {
  color: #212529; }

.bg-danger-500 {
  color: #fff; }

.bg-danger-600 {
  color: #fff; }

.bg-danger-700 {
  color: #fff; }

.bg-danger-800 {
  color: #fff; }

.bg-danger-900 {
  color: #fff; }

/* border colors*/
.border-blue {
  border-color: #0e73cc !important; }

.border-red {
  border-color: #fc4848 !important; }

.border-pink {
  border-color: #e91e63 !important; }

.border-yellow {
  border-color: #ffc107 !important; }

.border-gray {
  border-color: #8c8476 !important; }

.border-gray-dark {
  border-color: #38352f !important; }

.border-primary {
  border-color: #0e73cc !important; }

.border-success {
  border-color: #0f9e7b !important; }

.border-info {
  border-color: #00c2db !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #fc4848 !important; }

.border-blue-100 {
  border-color: #e7f1fa !important; }

.border-blue-200 {
  border-color: #c3dcf2 !important; }

.border-blue-300 {
  border-color: #87b9e6 !important; }

.border-blue-400 {
  border-color: #4a96d9 !important; }

.border-blue-500 {
  border-color: #0e73cc !important; }

.border-blue-600 {
  border-color: #0b5699 !important; }

.border-blue-700 {
  border-color: #073a66 !important; }

.border-blue-800 {
  border-color: #041d33 !important; }

.border-blue-900 {
  border-color: #02111f !important; }

.border-red-100 {
  border-color: #ffeded !important; }

.border-red-200 {
  border-color: #fed1d1 !important; }

.border-red-300 {
  border-color: #fea4a4 !important; }

.border-red-400 {
  border-color: #fd7676 !important; }

.border-red-500 {
  border-color: #fc4848 !important; }

.border-red-600 {
  border-color: #bd3636 !important; }

.border-red-700 {
  border-color: #7e2424 !important; }

.border-red-800 {
  border-color: #3f1212 !important; }

.border-red-900 {
  border-color: #260b0b !important; }

.border-pink-100 {
  border-color: #fde9ef !important; }

.border-pink-200 {
  border-color: #fac7d8 !important; }

.border-pink-300 {
  border-color: #f48fb1 !important; }

.border-pink-400 {
  border-color: #ef568a !important; }

.border-pink-500 {
  border-color: #e91e63 !important; }

.border-pink-600 {
  border-color: #af174a !important; }

.border-pink-700 {
  border-color: #750f32 !important; }

.border-pink-800 {
  border-color: #3a0819 !important; }

.border-pink-900 {
  border-color: #23050f !important; }

.border-yellow-100 {
  border-color: #fff9e6 !important; }

.border-yellow-200 {
  border-color: #fff0c1 !important; }

.border-yellow-300 {
  border-color: #ffe083 !important; }

.border-yellow-400 {
  border-color: #ffd145 !important; }

.border-yellow-500 {
  border-color: #ffc107 !important; }

.border-yellow-600 {
  border-color: #bf9105 !important; }

.border-yellow-700 {
  border-color: #806104 !important; }

.border-yellow-800 {
  border-color: #403002 !important; }

.border-yellow-900 {
  border-color: #261d01 !important; }

.border-gray-100 {
  border-color: #f4f3f1 !important; }

.border-gray-200 {
  border-color: #e2e0dd !important; }

.border-gray-300 {
  border-color: #c6c2bb !important; }

.border-gray-400 {
  border-color: #a9a398 !important; }

.border-gray-500 {
  border-color: #8c8476 !important; }

.border-gray-600 {
  border-color: #696359 !important; }

.border-gray-700 {
  border-color: #46423b !important; }

.border-gray-800 {
  border-color: #23211e !important; }

.border-gray-900 {
  border-color: #151412 !important; }

.border-gray-dark-100 {
  border-color: #ebebea !important; }

.border-gray-dark-200 {
  border-color: #cdcdcb !important; }

.border-gray-dark-300 {
  border-color: #9c9a97 !important; }

.border-gray-dark-400 {
  border-color: #6a6863 !important; }

.border-gray-dark-500 {
  border-color: #38352f !important; }

.border-gray-dark-600 {
  border-color: #2a2823 !important; }

.border-gray-dark-700 {
  border-color: #1c1b18 !important; }

.border-gray-dark-800 {
  border-color: #0e0d0c !important; }

.border-gray-dark-900 {
  border-color: #080807 !important; }

.border-primary-100 {
  border-color: #e7f1fa !important; }

.border-primary-200 {
  border-color: #c3dcf2 !important; }

.border-primary-300 {
  border-color: #87b9e6 !important; }

.border-primary-400 {
  border-color: #4a96d9 !important; }

.border-primary-500 {
  border-color: #0e73cc !important; }

.border-primary-600 {
  border-color: #0b5699 !important; }

.border-primary-700 {
  border-color: #073a66 !important; }

.border-primary-800 {
  border-color: #041d33 !important; }

.border-primary-900 {
  border-color: #02111f !important; }

.border-success-100 {
  border-color: #e7f5f2 !important; }

.border-success-200 {
  border-color: #c3e7de !important; }

.border-success-300 {
  border-color: #87cfbd !important; }

.border-success-400 {
  border-color: #4bb69c !important; }

.border-success-500 {
  border-color: #0f9e7b !important; }

.border-success-600 {
  border-color: #0b775c !important; }

.border-success-700 {
  border-color: #084f3e !important; }

.border-success-800 {
  border-color: #04281f !important; }

.border-success-900 {
  border-color: #021812 !important; }

.border-info-100 {
  border-color: #e6f9fb !important; }

.border-info-200 {
  border-color: #bff0f6 !important; }

.border-info-300 {
  border-color: #80e1ed !important; }

.border-info-400 {
  border-color: #40d1e4 !important; }

.border-info-500 {
  border-color: #00c2db !important; }

.border-info-600 {
  border-color: #0092a4 !important; }

.border-info-700 {
  border-color: #00616e !important; }

.border-info-800 {
  border-color: #003137 !important; }

.border-info-900 {
  border-color: #001d21 !important; }

.border-warning-100 {
  border-color: #fff9e6 !important; }

.border-warning-200 {
  border-color: #fff0c1 !important; }

.border-warning-300 {
  border-color: #ffe083 !important; }

.border-warning-400 {
  border-color: #ffd145 !important; }

.border-warning-500 {
  border-color: #ffc107 !important; }

.border-warning-600 {
  border-color: #bf9105 !important; }

.border-warning-700 {
  border-color: #806104 !important; }

.border-warning-800 {
  border-color: #403002 !important; }

.border-warning-900 {
  border-color: #261d01 !important; }

.border-danger-100 {
  border-color: #ffeded !important; }

.border-danger-200 {
  border-color: #fed1d1 !important; }

.border-danger-300 {
  border-color: #fea4a4 !important; }

.border-danger-400 {
  border-color: #fd7676 !important; }

.border-danger-500 {
  border-color: #fc4848 !important; }

.border-danger-600 {
  border-color: #bd3636 !important; }

.border-danger-700 {
  border-color: #7e2424 !important; }

.border-danger-800 {
  border-color: #3f1212 !important; }

.border-danger-900 {
  border-color: #260b0b !important; }

/*===================================
=            Main navbar            =
===================================*/
#website-navbar {
  font-family: "Roboto Condensed";
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  #website-navbar .navbar-nav {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
    margin-top: 1rem; }
    #website-navbar .navbar-nav .nav-item {
      line-height: 1.25rem;
      justify-content: center;
      align-items: stretch;
      flex-grow: 1; }
    #website-navbar .navbar-nav .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.875rem;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; }
      #website-navbar .navbar-nav .nav-link:hover, #website-navbar .navbar-nav .nav-link:focus, #website-navbar .navbar-nav .nav-link.active {
        text-decoration: none;
        background-color: #46423b; }

@media (min-width: 992px) {
  #website-navbar {
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.24); }
    #website-navbar .navbar-nav {
      margin-top: 0; }
      #website-navbar .navbar-nav .nav-link {
        font-size: 0.8125rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        height: 100%; }
    #website-navbar .navbar-brand {
      margin-bottom: 0;
      font-size: 1.0625rem; } }

/*===============================
=            Sidebar            =
===============================*/
#navbar-left-wrapper {
  display: none;
  position: fixed;
  top: 72px;
  padding-right: 0;
  width: 230px;
  height: 100%;
  background-color: #23211e;
  z-index: 2;
  box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.12), 1px 0px 1px rgba(0, 0, 0, 0.24); }
  #navbar-left-wrapper #navbar-left-collapse {
    display: none; }
  #navbar-left-wrapper ~ .container {
    padding-left: 230px; }
  @media (min-width: 992px) {
    #navbar-left-wrapper {
      display: block; }
      #navbar-left-wrapper ~ .container-fluid {
        padding-left: 245px; } }

/* navbar-left md, sm & xs devices*/
@media (max-width: 991.98px) {
  #navbar-left-wrapper #navbar-left-collapse {
    display: block; }
  #navbar-left-wrapper ~ .container {
    padding-left: 15px; }
  .w3-animate-left {
    position: relative;
    animation: animateleft 0.4s; }
  @keyframes animateleft {
    from {
      left: -230px;
      opacity: 0; }
    to {
      left: 0;
      opacity: 1; } } }

#navbar-left {
  z-index: 100;
  position: relative;
  width: 100%;
  color: #fff;
  background-color: #23211e;
  box-shadow: 0 1px 0 #030303; }
  #navbar-left ul,
  #navbar-left li {
    margin: 0;
    padding: 0; }
  #navbar-left > li {
    /* main links*/
    /* 2nd level*/ }
    #navbar-left > li > a {
      padding: 12px 20px 12px 18px;
      border-top: 1px solid #3e3b36;
      border-bottom: 1px solid #0d0c0b;
      text-shadow: 1px 1px 0 #3e3b36;
      color: #fff;
      background-color: #312e2a;
      font-size: 13px;
      font-weight: 400;
      /* active main links*/ }
      #navbar-left > li > a i {
        line-height: 20px; }
      #navbar-left > li > a:hover, #navbar-left > li > a.active {
        background-color: #a93030; }
    #navbar-left > li a[aria-expanded="true"] i,
    #navbar-left > li a[aria-expanded="false"] i {
      transition: all 0.3s ease-in; }
    #navbar-left > li a[aria-expanded="true"][aria-expanded="true"] i,
    #navbar-left > li a[aria-expanded="false"][aria-expanded="true"] i {
      rotate: -180deg; }
    #navbar-left > li a[aria-expanded="true"][aria-expanded="true"] ~ ul,
    #navbar-left > li a[aria-expanded="false"][aria-expanded="true"] ~ ul {
      border-top: 1px solid #494f4f;
      border-bottom: 1px solid #212424; }
    #navbar-left > li > ul > li {
      /* all secondary levels links*/
      width: 100%;
      /* 3rd level*/ }
      #navbar-left > li > ul > li a {
        font-size: 13px;
        font-weight: 300;
        line-height: 20px;
        display: block;
        padding: 5px 22px 5px 30px;
        text-decoration: none;
        text-shadow: none;
        border-top: 1px solid #4c4841;
        border-bottom: 1px solid #151412;
        text-shadow: 1px 1px 0 #3e3b36;
        color: #fff;
        background-color: #393631; }
        #navbar-left > li > ul > li a .badge {
          text-shadow: none;
          text-transform: uppercase; }
        #navbar-left > li > ul > li a i {
          line-height: 20px;
          font-size: 18px; }
        #navbar-left > li > ul > li a:hover, #navbar-left > li > ul > li a.active {
          background-color: #bd3636; }
      #navbar-left > li > ul > li:first-child a {
        border-top: none; }
      #navbar-left > li > ul > li:last-child a {
        border-bottom: none; }
      #navbar-left > li > ul > li > ul li {
        /* all secondary levels links*/
        width: 100%;
        list-style-type: square; }
        #navbar-left > li > ul > li > ul li a {
          font-size: 13px;
          line-height: 20px;
          display: block;
          padding: 5px 22px 5px 40px;
          text-decoration: none;
          text-shadow: none;
          color: rgba(255, 255, 255, 0.75);
          background-color: #3e3b36; }
          #navbar-left > li > ul > li > ul li a i {
            line-height: 20px;
            font-size: 18px; }
          #navbar-left > li > ul > li > ul li a:before {
            display: inline-block;
            content: "■";
            padding-right: 7px; }
          #navbar-left > li > ul > li > ul li a:hover, #navbar-left > li > ul > li > ul li a.active {
            background-color: #cf5757; }

/*====================================================
=            timeline list + badge-circle            =
====================================================*/
.badge-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -27px;
  top: -26px;
  height: 50px;
  width: 50px;
  min-width: 50px;
  font-size: 16.66667px;
  border-radius: 50%; }

.list-timeline {
  padding-left: 25px;
  padding-top: 25px; }
  .list-timeline > li {
    border-left: 4px solid #ddd; }
    .list-timeline > li .timeline-content {
      position: relative;
      top: -0.75em; }
    .list-timeline > li:last-child {
      border-left: 4px solid transparent; }

/* Cards (template isotope grid) */
.grid .card {
  display: block;
  position: relative;
  margin: 0 0 2rem 0;
  transition: box-shadow 0.25s;
  border-radius: 2px;
  padding: 20px;
  display: block;
  color: #38352f;
  min-height: 60px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
  .grid .card .badge {
    position: absolute;
    right: 20px;
    font-size: 12px;
    top: 50%;
    margin-top: -9px; }
  .grid .card.card-indigo {
    background-color: #3f51b5; }
    .grid .card.card-indigo .badge {
      background: #2b387c; }
  .grid .card.card-pink {
    background-color: #e91e63; }
    .grid .card.card-pink .badge {
      background: #aa1145; }
  .grid .card.card-info {
    background-color: #00c2db; }
    .grid .card.card-info .badge {
      background: #007e8f; }
  .grid .card.card-primary {
    background-color: #0e73cc; }
    .grid .card.card-primary.deprecated {
      background-color: rgba(14, 115, 204, 0.8); }
    .grid .card.card-primary .badge {
      background: #094b84; }
  .grid .card.card-success {
    background-color: #0f9e7b; }
    .grid .card.card-success .badge {
      background: #085845; }
  .grid .card .item {
    color: #fff;
    font-size: 1.0625rem; }
  .grid .card:hover {
    text-decoration: none; }
  .grid .card.has-icon {
    padding-left: 85px;
    padding-right: 95px; }
    .grid .card.has-icon:before {
      font-family: "icomoon";
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.75rem;
      color: #fff;
      border-radius: 0.25rem 0 0 0.25rem;
      width: 54px;
      height: 100%; }
    .grid .card.has-icon:after {
      content: " ";
      position: absolute;
      top: calc(50% - 6px);
      left: 54px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 6px; }
    .grid .card.has-icon.card-indigo:before {
      text-shadow: 1px 1px 3px #171e44;
      background: #2b387c; }
    .grid .card.has-icon.card-indigo:after {
      border-color: transparent transparent transparent #2b387c; }
    .grid .card.has-icon.card-indigo:hover {
      background: #5667c4; }
      .grid .card.has-icon.card-indigo:hover:before {
        background: #252f69; }
      .grid .card.has-icon.card-indigo:hover:after {
        border-color: transparent transparent transparent #252f69; }
    .grid .card.has-icon.card-pink:before {
      text-shadow: 1px 1px 3px #640a29;
      background: #aa1145; }
    .grid .card.has-icon.card-pink:after {
      border-color: transparent transparent transparent #aa1145; }
    .grid .card.has-icon.card-pink:hover {
      background: #ec417b; }
      .grid .card.has-icon.card-pink:hover:before {
        background: #930e3b; }
      .grid .card.has-icon.card-pink:hover:after {
        border-color: transparent transparent transparent #930e3b; }
    .grid .card.has-icon.card-info:before {
      text-shadow: 1px 1px 3px #003a42;
      background: #007e8f; }
    .grid .card.has-icon.card-info:after {
      border-color: transparent transparent transparent #007e8f; }
    .grid .card.has-icon.card-info:hover {
      background: #02e2ff; }
      .grid .card.has-icon.card-info:hover:before {
        background: #006875; }
      .grid .card.has-icon.card-info:hover:after {
        border-color: transparent transparent transparent #006875; }
    .grid .card.has-icon.card-primary:before {
      text-shadow: 1px 1px 3px #04223d;
      background: #094b84; }
    .grid .card.has-icon.card-primary:after {
      border-color: transparent transparent transparent #094b84; }
    .grid .card.has-icon.card-primary:hover {
      background: #3599f1; }
      .grid .card.has-icon.card-primary:hover:before {
        background: #063055; }
      .grid .card.has-icon.card-primary:hover:after {
        border-color: transparent transparent transparent #063055; }
    .grid .card.has-icon.card-success:before {
      text-shadow: 1px 1px 3px #02120e;
      background: #085845; }
    .grid .card.has-icon.card-success:after {
      border-color: transparent transparent transparent #085845; }
    .grid .card.has-icon.card-success:hover {
      background: #12c196; }
      .grid .card.has-icon.card-success:hover:before {
        background: #064132; }
      .grid .card.has-icon.card-success:hover:after {
        border-color: transparent transparent transparent #064132; }

.grid .deprecated .card.card-indigo, .grid .deprecated .card.card-info, .grid .deprecated .card.card-primary, .grid .deprecated .card.card-success {
  background-color: #e2e0dd; }
  .grid .deprecated .card.card-indigo h4, .grid .deprecated .card.card-info h4, .grid .deprecated .card.card-primary h4, .grid .deprecated .card.card-success h4 {
    color: #b3aea6; }
  .grid .deprecated .card.card-indigo .label, .grid .deprecated .card.card-info .label, .grid .deprecated .card.card-primary .label, .grid .deprecated .card.card-success .label {
    background: #bfbab4; }
  .grid .deprecated .card.card-indigo.has-icon:before, .grid .deprecated .card.card-info.has-icon:before, .grid .deprecated .card.card-primary.has-icon:before, .grid .deprecated .card.card-success.has-icon:before {
    text-shadow: 1px 1px 3px #9c958a;
    background: #bfbab4; }
  .grid .deprecated .card.card-indigo.has-icon:after, .grid .deprecated .card.card-info.has-icon:after, .grid .deprecated .card.card-primary.has-icon:after, .grid .deprecated .card.card-success.has-icon:after {
    border-color: transparent transparent transparent #bfbab4; }
  .grid .deprecated .card.card-indigo.has-icon:hover, .grid .deprecated .card.card-info.has-icon:hover, .grid .deprecated .card.card-primary.has-icon:hover, .grid .deprecated .card.card-success.has-icon:hover {
    background: #f4f3f2; }
    .grid .deprecated .card.card-indigo.has-icon:hover:before, .grid .deprecated .card.card-info.has-icon:hover:before, .grid .deprecated .card.card-primary.has-icon:hover:before, .grid .deprecated .card.card-success.has-icon:hover:before {
      background: #b3aea6; }
    .grid .deprecated .card.card-indigo.has-icon:hover:after, .grid .deprecated .card.card-info.has-icon:hover:after, .grid .deprecated .card.card-primary.has-icon:hover:after, .grid .deprecated .card.card-success.has-icon:hover:after {
      border-color: transparent transparent transparent #b3aea6; }

/*============================================
=            responsive card-deck            =
============================================*/
/* Bootstrap 4 breakpoints & gutter*/
/* $grid-gutter-width: 30px !default;*/
/* number of cards per line for each breakpoint*/
@media (min-width: 0) {
  .card-deck .card {
    flex: 0 0 calc(100% - 30px); } }

@media (min-width: 768px) {
  .card-deck .card {
    flex: 0 0 calc(50% - 30px); } }

@media (min-width: 992px) {
  .card-deck .card {
    flex: 0 0 calc(33.33333% - 30px); } }

/*=============================================
=            Global caret settings            =
=============================================*/
.dropdown-toggle:not(.sidebar-toggler):after {
  line-height: 22.5px;
  position: absolute;
  top: calc(50% - 7px);
  right: 1rem;
  display: block;
  width: 7px;
  height: 14px;
  margin: 0;
  content: " ";
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg);
  border: none;
  background-repeat: no-repeat; }

.dropdown-toggle {
  position: relative;
  padding-right: 2.5rem !important; }
  .dropdown-toggle:not(.dropdown-light):after {
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-fa-processed='' data-prefix='fas' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' class='svg-inline--fa fa-angle-right fa-w-8 fa-2x'%3E%3Cpath fill='%23a9a398' d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z' class=''%3E%3C/path%3E%3C/svg%3E"); }
  .dropdown-toggle.dropdown-light:after {
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-fa-processed='' data-prefix='fas' data-icon='angle-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' class='svg-inline--fa fa-angle-right fa-w-8 fa-2x'%3E%3Cpath fill='%23f4f3f1' d='M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z' class=''%3E%3C/path%3E%3C/svg%3E"); }
  .dropdown-toggle[aria-expanded="true"]:after {
    transform: rotate(90deg); }

/*==========================================================================
=            Filetree (http://jsfiddle.net/mehmetatas/fXzHS/2/)            =
==========================================================================*/
.tree li {
  margin: 0 0 !important;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0px 5px; }
  .tree li small {
    display: inline-block; }

.tree li::before {
  content: "";
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  right: auto;
  left: -20px;
  border-left: 1px solid #ccc;
  bottom: 50px; }

.tree li::after {
  content: "";
  position: absolute;
  top: 30px;
  width: 25px;
  height: 20px;
  right: auto;
  left: -20px;
  border-top: 1px solid #ccc; }

.tree li a[href="#"] {
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px; }
  .tree li a[href="#"].folder {
    background: #ffe79c;
    border: 1px solid #ffc203; }
  .tree li a[href="#"] ~ ul li a.folder ~ ul li a.folder {
    background: #fff3cf;
    border: 1px solid #ffdb69; }
    .tree li a[href="#"] ~ ul li a.folder ~ ul li a.folder ~ ul li a.folder {
      background: #fffdf8;
      border: 1px solid #ffe79c; }
  .tree li a[href="#"].file {
    background: #b1eef6;
    border: 1px solid #0095a8; }
  .tree li a[href="#"] ~ ul li a.folder ~ ul li a.folder ~ ul li a.file {
    background: #f6fdfe;
    border: 1px solid #38e8ff; }

/*Remove connectors before root*/
.tree > ul > li::before,
.tree > ul > li::after {
  border: 0; }

/*Remove connectors after last child*/
.tree li:last-child::before {
  height: 30px; }

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover,
.tree li a:hover + ul li a {
  color: #000; }
  .tree li a:hover.folder,
  .tree li a:hover + ul li a.folder {
    background: #ffdb69;
    border: 1px solid #cf9d00; }
  .tree li a:hover.file,
  .tree li a:hover + ul li a.file {
    background: #83e5f1;
    border: 1px solid #006875; }

/*Connector styles on hover*/
.tree li a:hover + ul li::after,
.tree li a:hover + ul li::before,
.tree li a:hover + ul::before,
.tree li a:hover + ul ul::before {
  border-color: #94a0b4; }

/*==============================
=            icomoon            =
==============================*/
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?rnh868");
  src: url("../fonts/icomoon.eot?rnh868#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?rnh868") format("truetype"), url("../fonts/icomoon.woff?rnh868") format("woff"), url("../fonts/icomoon.svg?rnh868#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-toggle-on:before {
  content: "\e902"; }

.icon-home:before {
  content: "\e901"; }

.icon-pencil2:before {
  content: "\e906"; }

.icon-eyedropper:before {
  content: "\e90a"; }

.icon-paint-format:before {
  content: "\e90c"; }

.icon-images:before {
  content: "\e90e"; }

.icon-cart:before {
  content: "\e93a"; }

.icon-coin-dollar:before {
  content: "\e93b"; }

.icon-lifebuoy:before {
  content: "\e941"; }

.icon-compass2:before {
  content: "\e94a"; }

.icon-calendar:before {
  content: "\e953"; }

.icon-user:before {
  content: "\e971"; }

.icon-users:before {
  content: "\e972"; }

.icon-user-check:before {
  content: "\e975"; }

.icon-spinner4:before {
  content: "\e97d"; }

.icon-search2:before {
  content: "\e986"; }

.icon-key2:before {
  content: "\e98e"; }

.icon-cog:before {
  content: "\e994"; }

.icon-hammer:before {
  content: "\e996"; }

.icon-glass:before {
  content: "\e9a0"; }

.icon-spoon-knife:before {
  content: "\e9a3"; }

.icon-airplane:before {
  content: "\e9af"; }

.icon-truck:before {
  content: "\e9b0"; }

.icon-switch:before {
  content: "\e9b6"; }

.icon-power-cord:before {
  content: "\e9b7"; }

.icon-earth:before {
  content: "\e9ca"; }

.icon-eye:before {
  content: "\e9ce"; }

.icon-eye-plus:before {
  content: "\e9cf"; }

.icon-eye-minus:before {
  content: "\e9d0"; }

.icon-eye-blocked:before {
  content: "\e9d1"; }

.icon-star-empty:before {
  content: "\e9d7"; }

.icon-star-half:before {
  content: "\e9d8"; }

.icon-star-full:before {
  content: "\e9d9"; }

.icon-plus:before {
  content: "\ea0a"; }

.icon-minus:before {
  content: "\ea0b"; }

.icon-info:before {
  content: "\ea0c"; }

.icon-cancel-circle:before {
  content: "\ea0d"; }

.icon-loop2:before {
  content: "\ea2e"; }

.icon-radio-checked:before {
  content: "\ea54"; }

.icon-mail:before {
  content: "\f03b"; }

.icon-mail-read:before {
  content: "\f03c"; }

.icon-search:before {
  content: "\f02e"; }

.icon-newspaper:before {
  content: "\e904"; }

.icon-profile:before {
  content: "\e923"; }

.icon-address-book:before {
  content: "\e944"; }

.icon-user-plus:before {
  content: "\e973"; }

.icon-gift:before {
  content: "\e99f"; }

.icon-arrow-right:before {
  content: "\ea34"; }

.icon-checkbox-unchecked:before {
  content: "\ea53"; }

.icon-upload:before {
  content: "\e961"; }

.icon-database:before {
  content: "\e964"; }

.icon-dynamic:before {
  content: "\e982"; }

.icon-stack:before {
  content: "\e92e"; }

.icon-bubble2:before {
  content: "\e96e"; }

.icon-tree:before {
  content: "\e9bc"; }

.icon-food:before {
  content: "\e600"; }

.icon-phone:before {
  content: "\e601"; }

.icon-car:before {
  content: "\e602"; }

.icon-rocket:before {
  content: "\e603"; }

.icon-chart:before {
  content: "\e604"; }

.icon-comments:before {
  content: "\e605"; }

.icon-check:before {
  content: "\e900"; }

.icon-tag:before {
  content: "\f02b"; }

.icon-check-square-o:before {
  content: "\f046"; }

.icon-chevron-up:before {
  content: "\f077"; }

.icon-chevron-down:before {
  content: "\f078"; }

.icon-key:before {
  content: "\f084"; }

.icon-unlock-alt:before {
  content: "\f13e"; }

.icon-bed:before {
  content: "\f236"; }

.icon-hotel:before {
  content: "\f236"; }

/*==============================
=            alerts            =
==============================*/
.has-icon {
  position: relative; }
  .has-icon.alert {
    padding-left: 70px; }
    .has-icon.alert:before {
      padding: 13px 0 0 13px;
      content: " "; }
  .has-icon:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 50px;
    height: 100%;
    border-radius: 3px 0 0 3px;
    background-repeat: no-repeat;
    background-position: center center; }
  .has-icon.alert:after {
    position: absolute;
    top: calc(50% - 6px);
    left: 50px;
    width: 0;
    height: 0;
    content: " ";
    border-width: 6px 0 6px 6px;
    border-style: solid; }
  .has-icon.has-icon-success:before {
    background-color: #0f9e7b;
    /* check-circle*/
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-fa-processed='' data-prefix='fas' data-icon='check-circle' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-check-circle fa-w-16'%3E%3Cpath fill='%23fff' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z' class=''%3E%3C/path%3E%3C/svg%3E");
    background-size: 30%; }
  .has-icon.alert-success:before {
    background-color: #0f9e7b;
    /* check*/
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-fa-processed='' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-check fa-w-16'%3E%3Cpath fill='%23fff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
    background-size: 30%; }
  .has-icon.alert-success:after {
    border-color: transparent transparent transparent #0f9e7b; }
  .has-icon.alert-success .var-value,
  .has-icon.alert-success .file-path {
    color: white;
    background-color: #0f9e7b; }
  .has-icon.alert-info:before {
    color: #00c2db;
    background-color: #00c2db;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-fa-processed='' data-prefix='fas' data-icon='info' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512' class='svg-inline--fa fa-info fa-w-6'%3E%3Cpath fill='%23fff' d='M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z' class=''%3E%3C/path%3E%3C/svg%3E");
    background-size: 11.25%;
    /* 30% * 192/512*/ }
  .has-icon.alert-info:after {
    border-color: transparent transparent transparent #00c2db; }
  .has-icon.alert-info .var-value,
  .has-icon.alert-info .file-path {
    background-color: #00c2db; }
  .has-icon.alert-warning:before {
    background-color: #ffc107;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-fa-processed='' data-prefix='fas' data-icon='exclamation-triangle' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' class='svg-inline--fa fa-exclamation-triangle fa-w-18'%3E%3Cpath fill='%23fff' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z' class=''%3E%3C/path%3E%3C/svg%3E");
    background-size: 33.75%;
    /* 30% * 576/512*/ }
  .has-icon.alert-warning:after {
    border-color: transparent transparent transparent #ffc107; }
  .has-icon.alert-danger:before {
    background-color: #fc4848;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-fa-processed='' data-prefix='fas' data-icon='exclamation-circle' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-exclamation-circle fa-w-16'%3E%3Cpath fill='%23fff' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z' class=''%3E%3C/path%3E%3C/svg%3E");
    background-size: 30%; }
  .has-icon.alert-danger:after {
    border-color: transparent transparent transparent #fc4848; }

.alert {
  position: relative;
  border: none; }
  .alert :first-child {
    margin-top: 0; }
  .alert p {
    margin-bottom: 0.5em; }
    .alert p:last-child {
      margin-bottom: 0; }
  .alert a {
    text-decoration: underline; }

/*====================================================================
=            social - https://codepen.io/migli/pen/mggqoK           =
====================================================================*/
.pace-done #share-wrapper {
  display: block; }

#share-wrapper {
  position: absolute;
  top: 37px;
  z-index: 1050;
  right: 50px;
  height: 40px;
  transform: translateY(-50%); }
  #share-wrapper ul {
    width: calc(2 * 45px);
    position: relative;
    left: calc(-50% + 22.5px); }
    #share-wrapper ul li {
      width: 45px; }
  #share-wrapper li > a,
  #share-wrapper label {
    color: #efefef;
    border-radius: 50%;
    text-decoration: none;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.45;
    cursor: pointer; }
    #share-wrapper li > a#share,
    #share-wrapper label#share {
      background: #4267b2;
      position: relative;
      z-index: 14;
      opacity: 0.75; }
      #share-wrapper li > a#share:hover,
      #share-wrapper label#share:hover {
        background: #4080ff; }
    #share-wrapper li > a#share-facebook,
    #share-wrapper label#share-facebook {
      background: #3b5998; }
    #share-wrapper li > a#share-twitter,
    #share-wrapper label#share-twitter {
      background: #00acee; }
    #share-wrapper li > a#share-pinterest,
    #share-wrapper label#share-pinterest {
      background: #e4405f; }
    #share-wrapper li > a#share-linkedin,
    #share-wrapper label#share-linkedin {
      background: #0077b5; }
    #share-wrapper li > a#share-reddit,
    #share-wrapper label#share-reddit {
      background: #ff4500; }
    #share-wrapper li > a#share-google-bookmarks,
    #share-wrapper label#share-google-bookmarks {
      background: #4285f4; }
    #share-wrapper li > a#share-mix,
    #share-wrapper label#share-mix {
      background: #ff8226; }
    #share-wrapper li > a#share-pocket,
    #share-wrapper label#share-pocket {
      background: #ee4056; }
    #share-wrapper li > a#share-digg,
    #share-wrapper label#share-digg {
      background: #2a2a2a; }
    #share-wrapper li > a#share-blogger,
    #share-wrapper label#share-blogger {
      background: #fda352; }
    #share-wrapper li > a#share-tumblr,
    #share-wrapper label#share-tumblr {
      background: #35465c; }
    #share-wrapper li > a#share-flipboard,
    #share-wrapper label#share-flipboard {
      background: #cc0000; }
    #share-wrapper li > a#share-hacker-news,
    #share-wrapper label#share-hacker-news {
      background: #ff6600; }
  #share-wrapper li {
    transition: all 200ms ease; }
    #share-wrapper li:nth-child(1) {
      transition-delay: 16.66667ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 13; }
    #share-wrapper li:nth-child(2) {
      transition-delay: 33.33333ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 12; }
    #share-wrapper li:nth-child(3) {
      transition-delay: 50ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 11; }
    #share-wrapper li:nth-child(4) {
      transition-delay: 66.66667ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 10; }
    #share-wrapper li:nth-child(5) {
      transition-delay: 83.33333ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 9; }
    #share-wrapper li:nth-child(6) {
      transition-delay: 100ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 8; }
    #share-wrapper li:nth-child(7) {
      transition-delay: 116.66667ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 7; }
    #share-wrapper li:nth-child(8) {
      transition-delay: 133.33333ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 6; }
    #share-wrapper li:nth-child(9) {
      transition-delay: 150ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 5; }
    #share-wrapper li:nth-child(10) {
      transition-delay: 166.66667ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 4; }
    #share-wrapper li:nth-child(11) {
      transition-delay: 183.33333ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 3; }
    #share-wrapper li:nth-child(12) {
      transition-delay: 200ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 2; }
    #share-wrapper li:nth-child(13) {
      transition-delay: 216.66667ms;
      opacity: 0;
      transform: translateY(-30%);
      z-index: 1; }
  #share-wrapper input:checked ~ label,
  #share-wrapper input:checked ~ ul li {
    transform: scale(1) translateY(0);
    opacity: 1; }

@media only screen and (min-width: 992px) {
  #share-wrapper {
    position: fixed;
    top: 230px;
    right: -20px; } }

.fb-like {
  top: 90px;
  left: 80px;
  width: 42px;
  position: absolute !important;
  z-index: 1050; }

@media only screen and (min-width: 992px) {
  .fb-like {
    position: fixed !important;
    top: 170px;
    right: 34px;
    left: auto; } }

/* Project Fonts
-------------------------------------------------- */
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/roboto-v18-latin-300.woff2") format("woff2"), url("../fonts/roboto-v18-latin-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v18-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v18-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/roboto-v18-latin-500.woff2") format("woff2"), url("../fonts/roboto-v18-latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Roboto Condensed"), local("RobotoCondensed-Regular"), url("../fonts/roboto-condensed-v16-latin-regular.woff2") format("woff2"), url("../fonts/roboto-condensed-v16-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* Project functions
-------------------------------------------------- */
/* Project variables
-------------------------------------------------- */
/* Project styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%; }

body {
  counter-reset: section; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

h1 {
  line-height: 0.9;
  margin-bottom: 2.5rem;
  font-weight: normal; }
  h1::first-letter,
  h1#jquery-plugins-title span {
    font-size: 2em;
    font-weight: 500; }
  h1#jquery-plugins-title::first-letter {
    font-size: 1em;
    font-weight: normal; }
  h1 small {
    font-size: 1.3125rem;
    font-weight: 300;
    line-height: 1;
    margin-left: 0.75rem; }

h2 {
  font-weight: 300;
  color: #8c8476;
  border-bottom: 1px solid #8c8476; }
  h2 small {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1; }

h3 small,
.h3 small {
  font-variant: normal; }

h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-weight: 300;
  color: #a9a398; }

h3,
.h3 {
  font-variant: small-caps; }

h4 small,
.h4 small {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  font-variant: normal; }

p.lead {
  font-weight: 400;
  color: #696359; }

pre,
code,
code[class*='language'] {
  font-size: 0.75rem; }

strong,
th {
  font-weight: 500; }

section#filter-forms legend,
#filter-forms#filter-forms legend {
  color: #fff;
  font-variant: small-caps;
  font-weight: 300;
  border-bottom: 1px solid #c6c2bb;
  margin-bottom: 0.5rem; }
  section#filter-forms legend ~ .input-field,
  #filter-forms#filter-forms legend ~ .input-field {
    margin-top: 0; }

section#filter-forms .material-form [type='radio']:checked + span,
section#filter-forms .material-form [type='radio']:not(:checked) + span,
section#filter-forms .material-form [type='checkbox'] + span:not(.lever),
#filter-forms#filter-forms .material-form [type='radio']:checked + span,
#filter-forms#filter-forms .material-form [type='radio']:not(:checked) + span,
#filter-forms#filter-forms .material-form [type='checkbox'] + span:not(.lever) {
  font-size: 0.875rem; }

section#filter-forms label,
#filter-forms#filter-forms label {
  font-weight: 300;
  margin-bottom: 0; }
  section#filter-forms label [type='radio']:checked + span,
  section#filter-forms label [type='checkbox']:checked + span,
  #filter-forms#filter-forms label [type='radio']:checked + span,
  #filter-forms#filter-forms label [type='checkbox']:checked + span {
    color: rgba(255, 255, 255, 0.9); }

section > h2,
#filter-forms > h2 {
  padding: 1rem;
  border-bottom: 1px solid #ddd; }

section > h3,
#filter-forms > h3 {
  white-space: nowrap; }
  section > h3:before, section > h3:after,
  #filter-forms > h3:before,
  #filter-forms > h3:after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #ffc107;
    margin-bottom: 3px; }
  section > h3:before,
  #filter-forms > h3:before {
    margin-right: 10px; }
  section > h3:after,
  #filter-forms > h3:after {
    margin-left: 12px; }

article > h3:before,
article > h4:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  margin-bottom: 3px;
  border-radius: 50%;
  background: #00c2db; }

article > h3:before {
  background: #00c2db; }

article > h4:before {
  margin-left: 20px;
  background: #ffc107; }

/* Lists
-------------------------------------------------- */
section ul:not(.list-unstyled):not(.tree-list):not(.list-inline):not(.picker__list):not(.select2-selection__rendered) > li {
  position: relative;
  list-style: none;
  margin-bottom: 0.5rem; }
  section ul:not(.list-unstyled):not(.tree-list):not(.list-inline):not(.picker__list):not(.select2-selection__rendered) > li strong {
    color: #23211e; }
  section ul:not(.list-unstyled):not(.tree-list):not(.list-inline):not(.picker__list):not(.select2-selection__rendered) > li ul:not(.list-unstyled):not(.tree-list) > li:before {
    content: ' ';
    width: 4px;
    height: 4px;
    background: #c6c2bb;
    border-radius: 0;
    display: inline-block;
    position: absolute;
    left: -30px;
    top: 0.75em; }
  section ul:not(.list-unstyled):not(.tree-list):not(.list-inline):not(.picker__list):not(.select2-selection__rendered) > li:before {
    content: ' ';
    width: 6px;
    height: 6px;
    background: #a9a398;
    display: inline-block;
    position: absolute;
    left: -30px;
    top: 0.55em; }

/* Horizontal definition lists
-------------------------------------------------- */
dt {
  font-weight: 500; }

dl.dl-horizontal {
  display: table;
  table-layout: fixed;
  /* min-width: 400px;*/
  margin-bottom: 60px; }
  dl.dl-horizontal dt,
  dl.dl-horizontal dd {
    width: auto;
    overflow: visible; }
  dl.dl-horizontal dt {
    display: table-cell;
    text-align: right;
    white-space: nowrap;
    font-weight: 500;
    padding: 7px 20px 7px 0; }
    dl.dl-horizontal dt > pre {
      margin-top: 3px; }
  dl.dl-horizontal dd {
    display: table-cell;
    padding: 7px 0; }
    dl.dl-horizontal dd.line-break {
      display: table-row; }

@media (min-width: 936px) {
  .dl-horizontal dt {
    min-width: 220px; } }

.badge:not(.badge-circle) {
  border-radius: 0; }

/* Cards
-------------------------------------------------- */
.card-title {
  font-weight: 500;
  color: #fc4848; }

/* Numbered titles
-------------------------------------------------- */
.numbered-title:before {
  counter-increment: section;
  content: counter(section);
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  line-height: 1.5em;
  background: #007e8f;
  color: #fff; }

/* Numbered lists
-------------------------------------------------- */
.numbered {
  position: relative;
  counter-reset: my-counter;
  list-style: none;
  padding-left: 40px !important; }

.numbered > dt:before,
.numbered > li:before {
  content: counter(my-counter);
  counter-increment: my-counter;
  position: absolute;
  left: 0;
  color: #fff;
  width: 1.5em;
  height: 1.5em;
  font-weight: normal;
  display: inline-block;
  text-align: center;
  border-radius: 50%; }

.numbered > dt[class^='col-']:before,
.numbered > li[class^='col-']:before {
  left: -25px; }

/* Code examples
-------------------------------------------------- */
.outputpre:before,
.output-code > prepre:before,
.form-code > prepre:before {
  content: '';
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: 0;
  width: 3em;
  height: 30px;
  letter-spacing: -1px;
  border-right: 1px solid #999;
  -webkit-user-select: none;
  user-select: none; }

.output:after,
.output-code > pre:after,
.form-code > pre:after {
  font-family: 'Roboto';
  font-size: 0.8125rem;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 15px;
  height: 24px;
  line-height: 24px;
  background: #46423b;
  color: #fff;
  border-radius: 0 0.25rem 0 0.25rem; }

.output-code {
  position: relative; }

.output {
  position: relative;
  padding: 20px;
  margin-bottom: 1em;
  border-top: 1px solid #efefef;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  .output:after {
    content: 'output'; }

.output-code > pre:after {
  content: 'output-code'; }

.form-code > pre:after {
  content: 'form code'; }

.form-element {
  padding: 20px 0 0; }

/* Project Classes
-------------------------------------------------- */
/* background md, sm & xs devices*/
.bg-img-dark {
  background: url("../images/backgrounds/dark-amber-sm.png") center no-repeat #33302b;
  background-size: cover; }

@media (min-width: 768px) {
  .bg-img-dark {
    background: url("../images/backgrounds/dark-amber-md.png") center no-repeat #33302b; } }

@media (min-width: 992px) {
  .bg-img-dark {
    background: url("../images/backgrounds/dark-amber-lg.png") center no-repeat #33302b; } }

.badge-lg {
  font-size: 100%; }

.bg-light {
  background: #f4f3f1 !important; }

.bg-pink:hover {
  color: white; }

.btn .icon-circle {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%; }

.btn.btn-lg .icon-circle {
  width: 28px;
  height: 28px;
  line-height: 28px; }

.border-dashed {
  border-style: dashed; }

.condensed {
  font-family: 'Roboto Condensed'; }

.dmca-badge {
  min-height: 100px; }

.has-separator {
  display: block;
  position: relative; }
  .has-separator:before, .has-separator:after {
    position: absolute;
    left: 50%;
    height: 1px;
    content: '';
    background: #c6c2bb; }
  .has-separator:before {
    bottom: -16px;
    width: 12%;
    margin-left: -6%; }
  .has-separator:after {
    bottom: -13px;
    width: 20%;
    margin-left: -10%; }

.medium, .file-path,
.var-type,
.var-value {
  font-size: 93.33333333333333%; }

.mh-100vh {
  min-height: 100vh; }

.nowrap {
  white-space: nowrap; }

.file-path,
.var-type,
.var-value {
  display: inline-block;
  padding: 0.15em 0.5em;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }
  .file-path.file-path,
  .var-type.file-path,
  .var-value.file-path {
    border-radius: 0.2rem;
    color: #212529;
    background-color: #f4f3f1; }
    a.file-path.file-path:hover, a.file-path.file-path:focus, .var-type.file-path:hover, .var-type.file-path:focus, .var-value.file-path:hover, .var-value.file-path:focus {
      color: #212529;
      background-color: #dedbd4; }
    a.file-path.file-path:focus, a.file-path.file-path.focus, .var-type.file-path:focus, .var-type.file-path.focus, .var-value.file-path:focus, .var-value.file-path.focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(244, 243, 241, 0.5); }
  .file-path.var-value,
  .var-type.var-value,
  .var-value.var-value {
    border-radius: 0.2rem;
    background: #e2e0dd;
    color: #151412; }

.alert-info .file-path {
  color: #fff;
  background-color: #00c2db; }
  a.alert-info .file-path:hover, a.alert-info .file-path:focus {
    color: #fff;
    background-color: #0095a8; }
  a.alert-info .file-path:focus, a.alert-info .file-path.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 194, 219, 0.5); }

.alert-warning .file-path {
  color: #212529;
  background-color: #ffc107; }
  a.alert-warning .file-path:hover, a.alert-warning .file-path:focus {
    color: #212529;
    background-color: #d39e00; }
  a.alert-warning .file-path:focus, a.alert-warning .file-path.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.underline {
  text-decoration: underline !important; }


